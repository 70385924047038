import { CheckoutActionsFormSection } from '../CheckoutActionsFormSection';
import { CustomerFormSection } from '../CustomerFormSection';
import { NoteFormSection } from '../NoteFormSection';
import { PaymentsFormSection } from '../PaymentsFormSection';
import type { CheckoutCompletedFormSectionProps } from './types';

export const CheckoutCompletedFormSection = (
  props: CheckoutCompletedFormSectionProps
) => {
  const { onPrint, payments, printActionLabel, printingEnabled } = props;

  return (
    <>
      <CheckoutActionsFormSection
        onPrint={onPrint}
        printActionLabel={printActionLabel}
        printingEnabled={printingEnabled}
      />
      <CustomerFormSection />
      <PaymentsFormSection payments={payments} />
      <NoteFormSection />
    </>
  );
};
