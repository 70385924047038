import { useIntl } from 'react-intl';
import { createFileRoute } from '@tanstack/react-router';
import { ForgotPassword } from '@afosto/auth-react';
import { Grid } from '@afosto/components';
import { translations } from './translations';

export const Route = createFileRoute('/auth/forgot')({
  component: ForgotPasswordComponent,
});

function ForgotPasswordComponent() {
  const intl = useIntl();

  return (
    <Grid item xs={12} sm={8} md={6}>
      <ForgotPassword
        defaultErrorMessage={intl.formatMessage(
          translations.passwordResetRequestFailed
        )}
        description={intl.formatMessage(translations.forgotPasswordDescription)}
        emailLabel={intl.formatMessage(translations.email)}
        signInLabel={intl.formatMessage(translations.signIn)}
        submitLabel={intl.formatMessage(translations.submit)}
        successDescription={intl.formatMessage(
          translations.forgotPasswordSuccessDescription
        )}
        successTitle={intl.formatMessage(
          translations.forgotPasswordSuccessTitle
        )}
        title={intl.formatMessage(translations.forgotPasswordTitle)}
      />
    </Grid>
  );
}
