import { useIntl } from 'react-intl';
import { ActionsList } from '../../../ActionsList';
import { CardSelector } from '../../../CardSelector';
import { Fieldset } from '../../../Fieldset';
import { Print } from '../../../../icons/solid';
import { translations } from './translations';
import type { CheckoutActionsFormSectionProps } from './types';

export const CheckoutActionsFormSection = (
  props: CheckoutActionsFormSectionProps
) => {
  const { onPrint, printActionLabel, printingEnabled = true } = props;

  const intl = useIntl();

  if (!printingEnabled) {
    return null;
  }

  return (
    <Fieldset title={intl.formatMessage(translations.actions)}>
      <ActionsList>
        <CardSelector
          Icon={Print}
          label={printActionLabel}
          onSelect={onPrint}
          hideSelectIcon
        />
      </ActionsList>
    </Fieldset>
  );
};
