import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Label, Typography } from '@afosto/components';
import { CardSelector } from '../../../CardSelector';
import { Currency } from '../../../Currency';
import { Fieldset } from '../../../Fieldset';
import { PAYMENT_METHOD_ICON_MAPPING } from '../../../../constants/iconMappings';
import { translations } from './translations';
import type { PaymentsFormSectionProps } from './types';

export const PaymentsFormSection = (props: PaymentsFormSectionProps) => {
  const { payments = [] } = props;

  const intl = useIntl();

  return (
    <Fieldset title={intl.formatMessage(translations.payment)}>
      {!payments?.length && (
        <CardSelector>
          <Typography color="gray.700" fontStyle="italic" variant="bodyLarge">
            <FormattedMessage {...translations.noPayment} />
          </Typography>
          <Box
            sx={{
              alignSelf: 'flex-start',
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
            }}
          >
            <Label
              color="error"
              label={intl.formatMessage(translations.notPaid)}
            />
          </Box>
        </CardSelector>
      )}
      {payments?.length > 0 && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {(payments || []).map((payment) => {
            const paymentMethodCode = payment?.method?.code?.toLowerCase();
            const PaymentMethodIcon =
              PAYMENT_METHOD_ICON_MAPPING[
                paymentMethodCode as keyof typeof PAYMENT_METHOD_ICON_MAPPING
              ];

            return (
              <CardSelector
                key={payment.id}
                Icon={PaymentMethodIcon}
                label={payment?.method?.name}
                description={
                  <Currency
                    currency={payment.currency}
                    value={payment.amountPaid}
                  />
                }
              >
                {payment.isCancelled && (
                  <Box
                    sx={{
                      alignSelf: 'flex-start',
                      display: 'flex',
                      flex: 1,
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Label
                      color="error"
                      label={intl.formatMessage(translations.cancelled)}
                      size="large"
                    />
                  </Box>
                )}
                {payment.isPaid && (
                  <Box
                    sx={{
                      alignSelf: 'flex-start',
                      display: 'flex',
                      flex: 1,
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Label
                      color="success"
                      label={intl.formatMessage(translations.paid)}
                      size="large"
                    />
                  </Box>
                )}
              </CardSelector>
            );
          })}
        </Box>
      )}
    </Fieldset>
  );
};
