import dutch from '@translations/compiled-locales/pos/nl.json';
import english from '@translations/compiled-locales/pos/en.json';

export const INTL_DEFAULT_LOCALE = 'en';

export const INTL_MESSAGES = {
  en: english,
  'en-au': english,
  'en-bz': english,
  'en-ca': english,
  'en-ie': english,
  'en-jm': english,
  'en-nz': english,
  'en-za': english,
  'en-tt': english,
  'en-gb': english,
  'en-us': english,
  nl: dutch,
  'nl-nl': dutch,
  'nl-be': dutch,
};
