import {
  graphQLClient,
  gql,
  type GraphQLRequestOptions,
} from '@afosto/graphql-client';

export interface CancelOrderItemsResponse {
  startItemCancellationProcess: {
    items: { id: string }[];
  };
}

export interface CancelOrderItemsItemInput {
  contraId: string;
  id: string;
}

export interface CancelOrderItemsInput {
  orderId: string;
  items: CancelOrderItemsItemInput[];
  requestOptions?: GraphQLRequestOptions<CancelOrderItemsResponse>;
}

export const cancelOrderItems = async ({
  orderId,
  items,
  requestOptions = {},
}: CancelOrderItemsInput) => {
  const mutation = gql`
    mutation CancelOrderItems($input: StartItemCancellationProcessInput!) {
      startItemCancellationProcess(input: $input) {
        items {
          id
        }
      }
    }
  `;

  return graphQLClient.request<CancelOrderItemsResponse>(
    mutation,
    {
      input: {
        orderId,
        items,
      },
    },
    requestOptions
  );
};
