import { useCallback, useEffect, useState } from 'react';
import { useGetLatest } from '@afosto/hooks';
import type { Channel } from '../../../types';

export type SearchType = 'products' | 'orders' | 'contacts' | 'organisations';

export interface UseSearchOptions {
  channel: Channel | null;
}

export const useSearch = (options: UseSearchOptions) => {
  const { channel } = options || {};

  const [searchType, setSearchType] = useState<SearchType>('products');
  const [showSearch, setShowSearch] = useState(false);
  const getLatestShowSearch = useGetLatest(showSearch);

  const closeSearch = useCallback(() => {
    setSearchType('products');
    setShowSearch(false);
  }, []);

  const openSearch = useCallback((type?: SearchType) => {
    if (type) {
      setSearchType(type);
    }

    setShowSearch(true);
  }, []);

  useEffect(() => {
    const latestShowSearch = getLatestShowSearch();

    if (latestShowSearch) {
      setShowSearch(false);
    }
  }, [channel?.id, getLatestShowSearch]);

  return {
    closeSearch,
    openSearch,
    searchType,
    setSearchType,
    showSearch,
  };
};
