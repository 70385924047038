import { useEffect, useRef } from 'react';
import onScanJs from 'onscan.js';

export interface UseScanner {
  onScan: (barcode: string, options?: Record<string, never>) => void;
  enabled?: boolean;
  /* By default, every key is cast to uppercase. Use this setting to disable the forceful uppercase casting,
   */
  disableForcedCasing?: boolean;
}

const altMapping = {
  '0029': String.fromCharCode(29),
};

const symbolsRegex =
  /^[\wáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ!@#$%^&*(),.?"'`~:;{}|<>\-_=+[\]\\/ ]$/;

export const useScanner = ({
  onScan,
  enabled = true,
  disableForcedCasing = false,
}: UseScanner) => {
  const isAttachedRef = useRef(false);

  useEffect(() => {
    if (enabled && !isAttachedRef.current) {
      isAttachedRef.current = true;
      let altKey = false;
      let altValue = '';

      onScanJs.attachTo(document, {
        ignoreIfFocusOn: 'input,textarea',
        minLength: 2,
        suffixKeyCodes: [13],
        reactToPaste: true,
        keyCodeMapper: (e: KeyboardEvent) => {
          // Check ALT and CTRL keys for GS1 group separators
          if (e.key?.toLowerCase() === 'alt') {
            altKey = true;
          }

          if (altKey && e.altKey) {
            altValue += `${onScanJs.decodeKeyEvent(e)}`;
            return '';
          }

          if (altKey && !e.altKey) {
            const scannedValue = `${
              altMapping[altValue as keyof typeof altMapping] || altValue
            }${onScanJs.decodeKeyEvent(e)}`;

            altKey = false;
            altValue = '';

            return scannedValue;
          }

          if (e.key === ']' && e.ctrlKey) {
            return String.fromCharCode(29);
          }

          if (e.altKey) {
            return '';
          }

          // Check for symbols
          if (symbolsRegex.test(e.key)) {
            return e.key;
          }

          return onScanJs.decodeKeyEvent(e);
        },
        onScan: (scannedCode: string) => {
          onScan(scannedCode);
        },
      });
    } else if (!enabled && isAttachedRef.current) {
      onScanJs.detachFrom(document);
      isAttachedRef.current = false;
    }

    return () => {
      if (isAttachedRef.current) {
        onScanJs.detachFrom(document);
        isAttachedRef.current = false;
      }
    };
  }, [enabled, disableForcedCasing, onScan]);
};
