import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import apiClient from '@afosto/api-client';
import {
  Box,
  Button,
  IconStatusWrapper,
  Link,
  Typography,
} from '@afosto/components';
import { useGetLatest } from '@afosto/hooks';
import { wait } from '@afosto/utils';
import { useQuery } from '@tanstack/react-query';
import { Currency } from '../Currency';
import { Fieldset } from '../Fieldset';
import { FullscreenDialog } from '../FullscreenDialog';
import { Loader } from '../Loader';
import { useOrder } from '../OrderProvider/hooks/useOrder';
import { PaymentMethodsList } from '../PaymentMethodsList';
import { usePrint } from '../PrintProvider/hooks/usePrint';
import { CheckoutCompletedFormSection } from './components/CheckoutCompletedFormSection';
import { ConfirmMakePaymentLaterFormSection } from './components/ConfirmMakePaymentLaterFormSection';
import { PaymentFailedFormSection } from './components/PaymentFailedFormSection';
import { CheckCircle } from '../../icons/solid';
import { PAYMENT_METHOD_ICON_MAPPING } from '../../constants/iconMappings';
import { getOrderPaymentMethods, type OrderPayment } from '../../queries';
import { getErrorMessage } from '../../utils';
import { translations } from './translations';
import type { CheckoutDialogProps } from './types';
import type { Invoice, Order, PaymentMethod } from '../../types';

export const CheckoutDialog = (props: CheckoutDialogProps) => {
  const { open = false, onClose, ...otherProps } = props;

  const intl = useIntl();
  const {
    addPaymentMethodToOrder,
    createInvoiceForItems,
    changeOrderStateToOpen,
    createOrderPayment,
    fetchOrder,
    getOrderPayment,
    markPaymentAsPaid,
    openCashDrawer,
    order,
    printInvoice,
    printOrder,
    setOrder,
  } = useOrder();
  const { printingEnabled } = usePrint();
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [processedPayments, setProcessedPayments] = useState<OrderPayment[]>(
    []
  );
  const [checkoutCompleted, setCheckoutCompleted] = useState(false);
  const [invoice, setInvoice] = useState<Invoice | null>(null);
  const [isChangingOrderOpenState, setIsChangingOrderOpenState] =
    useState(false);
  const [orderCompleted, setOrderCompleted] = useState(false);
  const [paymentErrorMessage, setPaymentErrorMessage] = useState('');
  const [paymentFailed, setPaymentFailed] = useState(false);
  const [remainingBalance, setRemainingBalance] = useState(0);
  const [showConfirmMakePaymentLater, setShowConfirmMakePaymentLater] =
    useState(false);
  const getLatestOrder = useGetLatest<Order | null>(order);
  const paidPayments = processedPayments.filter((payment) => payment.isPaid);
  const failedPayment = [...processedPayments]
    .reverse()
    .find((payment) => !payment.isPaid);
  const hasPaidPayments = paidPayments.length > 0;
  const totalPaid = paidPayments.reduce(
    (acc, payment) => acc + payment.amountPaid,
    0
  );

  useEffect(() => {
    if (open) {
      const latestOrder = getLatestOrder();
      setRemainingBalance(
        (latestOrder?.total || 0) -
          (latestOrder?.acceptance?.amountCovered || 0)
      );
    }
  }, [getLatestOrder, open]);

  const { data: paymentMethods, isFetched } = useQuery({
    ...getOrderPaymentMethods(order?.id || ''),
    enabled: !!order?.id && open,
  });

  const handleBackToPaymentMethods = () => {
    setPaymentErrorMessage('');
    setPaymentFailed(false);
  };

  const getInvoiceActionForOrder = (providedOrder: Order) => {
    return (providedOrder?.actions || []).find(
      (orderAction) => orderAction.action?.toLowerCase() === 'invoice'
    );
  };

  const waitForOrderBeingProcessed = async () => {
    const updatedOrder = await fetchOrder(order?.id || '');
    const { isInvoicingEnabled } = updatedOrder?.channel?.options || {};

    const invoiceAction = getInvoiceActionForOrder(updatedOrder);
    const invoicingReady =
      !isInvoicingEnabled || (isInvoicingEnabled && invoiceAction);

    if (!invoicingReady) {
      await wait(100);
      return waitForOrderBeingProcessed();
    }

    return updatedOrder;
  };

  const createInvoiceForOrder = async (providedOrder: Order) => {
    const { isInvoicingEnabled } = providedOrder?.channel?.options || {};
    const invoiceAction = getInvoiceActionForOrder(providedOrder);

    if (!isInvoicingEnabled) {
      return;
    }

    if (invoiceAction) {
      const createdInvoiceResponse = await createInvoiceForItems({
        items: invoiceAction.ids,
      });
      const { invoice: createdInvoice } =
        createdInvoiceResponse?.invoiceItems || {};

      if (createdInvoice) {
        setInvoice(createdInvoice);
      }
    }
  };

  const handleStartPayment = async (selectedPaymentMethod: PaymentMethod) => {
    try {
      if (!order) {
        throw new Error('Order not found');
      }

      const beforePaymentBalance =
        (order?.total || 0) - (order?.acceptance?.amountCovered || 0);

      setIsProcessingPayment(true);
      setPaymentErrorMessage('');
      setCheckoutCompleted(false);
      setPaymentFailed(false);

      const [firstIssuer] = selectedPaymentMethod?.issuers || [];

      const response = await addPaymentMethodToOrder({
        methodId: selectedPaymentMethod.id,
        ...(firstIssuer ? { issuerId: firstIssuer.id } : {}),
      });

      let createdPayment: OrderPayment | null = null;

      if (!selectedPaymentMethod.isManual) {
        const { proceedingStep } =
          response?.addPaymentMethodToOrder?.order?.options || {};
        const { options } = proceedingStep?.proceeding || {};
        const [firstOption] = options || [];

        if (!firstOption?.url) {
          throw new Error('No payment option available');
        }

        const paymentResponse = await apiClient.request({
          url: firstOption.url,
          method: 'get',
        });
        createdPayment = paymentResponse?.data?.data || null;
      } else {
        const response = await createOrderPayment({
          payment: {
            amount: remainingBalance,
          },
        });
        const paymentId = response?.data?.id;

        if (!paymentId) {
          throw new Error('Something went wrong while creating the payment');
        }

        await markPaymentAsPaid({
          payment: {
            id: paymentId,
            amount: remainingBalance,
          },
        });
        createdPayment = await getOrderPayment(paymentId);
      }

      if (createdPayment) {
        setProcessedPayments((currentProcessedPayments) => [
          ...currentProcessedPayments,
          {
            ...createdPayment,
            method: selectedPaymentMethod,
          },
        ]);
      }

      if (createdPayment?.isPaid) {
        if (
          printingEnabled &&
          selectedPaymentMethod?.code?.toLowerCase() === 'cash'
        ) {
          openCashDrawer().catch(() => {
            // Do nothing.
          });
        }

        const updatedOrder = await waitForOrderBeingProcessed();

        if (updatedOrder) {
          setOrder(updatedOrder);
        }

        const remainingBalance =
          beforePaymentBalance - createdPayment.amountPaid;

        if (remainingBalance > 0) {
          setRemainingBalance(remainingBalance);
          // TODO: Another payment should be processed
        } else {
          await createInvoiceForOrder(updatedOrder);
          setRemainingBalance(remainingBalance);
          setCheckoutCompleted(true);
        }
      } else {
        setPaymentFailed(true);
      }

      setIsProcessingPayment(false);
    } catch (error) {
      const message = getErrorMessage(error as Error);

      setPaymentErrorMessage(message);
      setPaymentFailed(true);
      setIsProcessingPayment(false);
    }
  };

  const handlePrintInvoice = async () => {
    try {
      if (invoice) {
        await printInvoice({ invoice, payments: paidPayments });
      }
    } catch {
      // TODO: Do something with error.
    }
  };

  const handlePrintOrder = async () => {
    try {
      await printOrder({ payments: paidPayments });
    } catch {
      // TODO: Do something with error.
    }
  };

  const handleRetryPayment = () => {
    if (failedPayment?.method) {
      handleStartPayment(failedPayment?.method).catch(() => {
        // Do nothing.
      });
    }
  };

  const handleCancelMakePaymentLater = () => {
    setShowConfirmMakePaymentLater(false);
  };

  const handleCompleteOrder = async () => {
    try {
      if (!order) {
        return;
      }

      let updatedOrder = order;

      if (order.state?.toLowerCase() === 'concept') {
        updatedOrder = await changeOrderStateToOpen();
      }

      if (order.state?.toLowerCase() === 'open') {
        const invoiceAction = getInvoiceActionForOrder(order);

        if (!invoiceAction) {
          updatedOrder = await waitForOrderBeingProcessed();
        }

        await createInvoiceForOrder(updatedOrder);
      }

      setOrderCompleted(true);
      setCheckoutCompleted(true);
    } catch {
      // TODO: Do something with error.
    }
  };

  const handleMakePaymentLater = async () => {
    try {
      setIsChangingOrderOpenState(true);

      await changeOrderStateToOpen();

      setShowConfirmMakePaymentLater(false);
      setCheckoutCompleted(true);
      setIsChangingOrderOpenState(false);
    } catch {
      // TODO: Do something with error.
      setIsChangingOrderOpenState(false);
    }
  };

  const handleShowConfirmMakePaymentLater = () => {
    setShowConfirmMakePaymentLater(true);
  };

  const handleClose = () => {
    if (checkoutCompleted) {
      setOrder(null);
    }

    if (onClose && typeof onClose === 'function') {
      onClose();
    }
  };

  const handleExited = () => {
    setIsChangingOrderOpenState(false);
    setIsProcessingPayment(false);
    setCheckoutCompleted(false);
    setInvoice(null);
    setOrderCompleted(false);
    setPaymentErrorMessage('');
    setPaymentFailed(false);
    setProcessedPayments([]);
    setRemainingBalance(0);
    setShowConfirmMakePaymentLater(false);
  };

  return (
    <FullscreenDialog
      {...otherProps}
      description={intl.formatMessage(
        translations[
          remainingBalance > 0 ? 'description' : 'noOutstandingBalance'
        ]
      )}
      dialogHeaderProps={
        checkoutCompleted
          ? {
              title: intl.formatMessage(
                translations[
                  hasPaidPayments
                    ? 'paymentAccepted'
                    : orderCompleted
                    ? 'orderCompleted'
                    : 'orderOpened'
                ]
              ),
              description: (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography
                    color="gray.900"
                    component="span"
                    fontWeight={500}
                    variant="bodyLarge"
                  >
                    <Currency
                      currency={order?.currency}
                      value={hasPaidPayments ? totalPaid : remainingBalance}
                    />
                  </Typography>
                  {!processedPayments.length && !orderCompleted && (
                    <Typography
                      color="gray.800"
                      component="span"
                      variant="bodyLarge"
                    >
                      {intl.formatMessage(translations.underpaid).toLowerCase()}
                    </Typography>
                  )}
                  {orderCompleted && (
                    <Typography
                      color="gray.800"
                      component="span"
                      variant="bodyLarge"
                    >
                      {intl
                        .formatMessage(translations.noPaymentRequired)
                        .toLowerCase()}
                    </Typography>
                  )}
                  {hasPaidPayments && (
                    <>
                      <Typography
                        color="gray.800"
                        component="span"
                        variant="bodyLarge"
                      >
                        {intl
                          .formatMessage(translations.paidWith)
                          .toLowerCase()}
                        :
                      </Typography>
                      {paidPayments.map((payment) => {
                        const paymentMethodCode =
                          payment?.method?.code?.toLowerCase();
                        const PaymentMethodIcon =
                          PAYMENT_METHOD_ICON_MAPPING[
                            paymentMethodCode as keyof typeof PAYMENT_METHOD_ICON_MAPPING
                          ];

                        return (
                          <Box
                            key={payment.id}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 0.5,
                            }}
                          >
                            {PaymentMethodIcon && (
                              <PaymentMethodIcon sx={{ fontSize: 24 }} />
                            )}
                            <Typography
                              color="gray.800"
                              component="span"
                              variant="bodyLarge"
                            >
                              {payment?.method?.name}
                            </Typography>
                          </Box>
                        );
                      })}
                    </>
                  )}
                </Box>
              ),
              startAdornment: (
                <IconStatusWrapper
                  color={
                    hasPaidPayments || orderCompleted ? 'success' : 'warning'
                  }
                  IconComponent={CheckCircle}
                  size="medium"
                />
              ),
            }
          : {}
      }
      hideDialogHeader={
        isProcessingPayment || paymentFailed || showConfirmMakePaymentLater
      }
      hideDialogHeaderDivider={!checkoutCompleted}
      onClose={!isProcessingPayment ? handleClose : undefined}
      open={open}
      title={<Currency currency={order?.currency} value={remainingBalance} />}
      topBarProps={{
        actions: !isProcessingPayment ? (
          <>
            {!checkoutCompleted && !paymentFailed && (
              <Link
                color="gray.900"
                component="button"
                disabled={isChangingOrderOpenState}
                onClick={handleClose}
                fontWeight={500}
                type="button"
                variant="bodyLarge"
              >
                <FormattedMessage {...translations.back} />
              </Link>
            )}
            {checkoutCompleted && (
              <Link
                component="button"
                onClick={handleClose}
                fontWeight={500}
                type="button"
                variant="bodyLarge"
              >
                <FormattedMessage {...translations.close} />
              </Link>
            )}
            {paymentFailed && !failedPayment?.isCancelled && (
              <Link
                component="button"
                onClick={handleRetryPayment}
                fontWeight={500}
                type="button"
                variant="bodyLarge"
              >
                <FormattedMessage {...translations.retry} />
              </Link>
            )}
          </>
        ) : undefined,
      }}
      TransitionProps={{ onExited: handleExited }}
    >
      {isProcessingPayment && (
        <Loader
          message={`${intl.formatMessage(translations.processingPayment)}...`}
          sx={{
            mt: 6,
          }}
        />
      )}
      {!isProcessingPayment &&
        !checkoutCompleted &&
        !paymentFailed &&
        !showConfirmMakePaymentLater && (
          <>
            {remainingBalance > 0 && (
              <>
                <Button
                  color="secondary"
                  onClick={handleShowConfirmMakePaymentLater}
                  size="extraLarge"
                  variant="outlined"
                  sx={{ mb: 1 }}
                  fullWidth
                >
                  <FormattedMessage {...translations.makePaymentLater} />
                </Button>
                {isFetched && (
                  <Fieldset
                    title={intl.formatMessage(
                      translations.availablePaymentMethods
                    )}
                    sx={{ mb: 6, '&:first-of-type': { mt: 0 } }}
                  >
                    <PaymentMethodsList
                      onSelectPaymentMethod={handleStartPayment}
                      paymentMethods={paymentMethods || []}
                    />
                  </Fieldset>
                )}
              </>
            )}
            {remainingBalance <= 0 && (
              <Button
                color="primary"
                onClick={handleCompleteOrder}
                size="extraLarge"
                sx={{ mb: 1 }}
                fullWidth
              >
                <FormattedMessage {...translations.completeOrder} />
              </Button>
            )}
          </>
        )}
      {showConfirmMakePaymentLater && (
        <ConfirmMakePaymentLaterFormSection
          isProcessing={isChangingOrderOpenState}
          onCancel={handleCancelMakePaymentLater}
          onProceed={handleMakePaymentLater}
        />
      )}
      {!isProcessingPayment && checkoutCompleted && (
        <CheckoutCompletedFormSection
          onPrint={invoice ? handlePrintInvoice : handlePrintOrder}
          printActionLabel={intl.formatMessage(
            translations[invoice ? 'printReceipt' : 'printOrder']
          )}
          printingEnabled={printingEnabled}
          payments={processedPayments}
        />
      )}
      {!isProcessingPayment && paymentFailed && (
        <PaymentFailedFormSection
          errorMessage={paymentErrorMessage}
          onBack={handleBackToPaymentMethods}
          onRetry={handleRetryPayment}
          payment={failedPayment}
        />
      )}
    </FullscreenDialog>
  );
};
