import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { setupApiClient } from './utils/setupApiClient';
import { setupCustomValidation } from './utils/setupCustomValidation';
import { router } from './router';
import { setupSentry } from './utils/setupSentry';
import { App } from './App';
import { RouterProvider } from './components/RouterProvider';

setupApiClient();
setupCustomValidation();
setupSentry(router);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <StrictMode>
    <App>
      <RouterProvider />
    </App>
  </StrictMode>
);
