import { PrintContext } from './PrintContext';
import { SelectPrinterDialog } from '../SelectPrinterDialog';
import { usePrintProvider } from './hooks/usePrintProvider';
import type { PrintProviderProps } from './types';

export const PrintProvider = (props: PrintProviderProps) => {
  const { children } = props;

  const printProviderState = usePrintProvider();
  const {
    closeSelectPrinterDialog,
    exitedSelectPrinterDialog,
    selectPrinter,
    selectPrinterDialogIntent,
    showSelectPrinterDialog,
  } = printProviderState;

  return (
    <PrintContext.Provider value={printProviderState}>
      {children}
      <SelectPrinterDialog
        onClose={
          selectPrinterDialogIntent !== 'installation'
            ? closeSelectPrinterDialog
            : undefined
        }
        onExited={exitedSelectPrinterDialog}
        onSelectPrinter={selectPrinter}
        open={showSelectPrinterDialog}
      />
    </PrintContext.Provider>
  );
};
