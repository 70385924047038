import { ScrollContainer } from '@afosto/components';
import { CategoryShortcut } from '../CategoryShortcut';
import { CustomerShortcut } from '../CustomerShortcut';
import { ManuallyAddProductShortcut } from '../ManuallyAddProductShortcut';
import { CancelProductsShortcut } from '../CancelProductsShortcut';
import { OrderDiscountShortcut } from '../OrderDiscountShortcut';
import { OrderNoteShortcut } from '../OrderNoteShortcut';
import * as Styled from './Shortcuts.styles';

export const Shortcuts = () => {
  return (
    <ScrollContainer>
      <Styled.Container>
        <CustomerShortcut />
        <CancelProductsShortcut />
        <ManuallyAddProductShortcut />
        <OrderDiscountShortcut />
        <OrderNoteShortcut />
        <CategoryShortcut />
      </Styled.Container>
    </ScrollContainer>
  );
};
