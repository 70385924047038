import { RouterProvider as TanstackRouterProvider } from '@tanstack/react-router';
import { useAuthentication } from '@afosto/auth-react';
import { router } from '../../router';

export const RouterProvider = () => {
  const authContext = useAuthentication();

  return (
    <TanstackRouterProvider router={router} context={{ auth: authContext }} />
  );
};
