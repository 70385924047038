import { createFileRoute } from '@tanstack/react-router';
import { OrdersSearch } from '../../../components/OrdersSearch';

export const Route = createFileRoute('/_protected/orders/$type')({
  component: RouteComponent,
});

function RouteComponent() {
  const { type } = Route.useParams();

  return <OrdersSearch type={type} />;
}
