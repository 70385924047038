import { useIntl } from 'react-intl';
import { createFileRoute } from '@tanstack/react-router';
import { TwoFactor } from '@afosto/auth-react';
import { Grid } from '@afosto/components';
import { translations } from './translations';

export const Route = createFileRoute('/auth/2fa')({
  component: TwoFactorComponent,
});

function TwoFactorComponent() {
  const intl = useIntl();

  return (
    <Grid item xs={12} sm={8} md={6}>
      <TwoFactor
        backupButtonLabel={intl.formatMessage(
          translations.twoFactorBackupButtonLabel
        )}
        defaultErrorMessage={intl.formatMessage(
          translations.twoFactorAuthenticationFailed
        )}
        description={intl.formatMessage(translations.twoFactorDescription)}
        securityCodeLabel={intl.formatMessage(translations.securityCode)}
        title={intl.formatMessage(translations.twoFactorTitle)}
        verifyLabel={intl.formatMessage(translations.verify)}
      />
    </Grid>
  );
}
