import { useCallback, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useOrderItemDiscount } from './useOrderItemDiscount';
import {
  addItemsToOrder as addItemsToOrderMutationRequest,
  type AddItemsToOrderInput,
  // approveOrderItems as approveOrderItemsMutationRequest,
  // type ApproveOrderItemsInput,
  type CreateOrderInput,
  cancelOrderItems as cancelOrderItemsMutationRequest,
  type CancelOrderItemsInput,
  removeItemsFromOrder as removeItemsFromOrderMutationRequest,
  type OrderItemInput,
} from '../../../mutations';
import { beepError, beepSuccess } from '../../../utils';
import {
  getProductByGtin as getProductByGtinOptions,
  getProductByScanDecode as getProductByScanDecodeOptions,
} from '../../../queries';
import type { Category } from '../../CategoryProvider';
import type { Order, OrderItem } from '../../../types';

export interface UseOrderItemsOptions {
  category?: Category | null;
  createOrder: (input?: Omit<CreateOrderInput, 'channelId'>) => Promise<Order>;
  fetchOrder: (id: string) => Promise<Order | null>;
  loadOrder: (id: string) => Promise<Order | null>;
  order: Order | null;
  setOrder: (order: Order) => void;
}

export interface ChangeItemQuantityOnOrderInput {
  item: OrderItem;
  quantity: number;
}

export const useOrderItems = (options: UseOrderItemsOptions) => {
  const { category, createOrder, fetchOrder, loadOrder, order, setOrder } =
    options || {};

  const queryClient = useQueryClient();

  const [selectedOrderItem, setSelectedOrderItem] = useState<OrderItem | null>(
    null
  );
  const [showCancelOrderItemsDialog, setShowCancelOrderItemsDialog] =
    useState(false);
  const [showManageOrderItemDialog, setShowManageOrderItemDialog] =
    useState(false);
  const [showManuallyAddProductDialog, setShowManuallyAddProductDialog] =
    useState(false);

  const itemDiscount = useOrderItemDiscount({
    loadOrder,
    order,
    selectedOrderItem,
    setSelectedOrderItem,
  });

  const { mutateAsync: addItemsToOrderMutation } = useMutation({
    mutationFn: addItemsToOrderMutationRequest,
  });

  // const { mutateAsync: approveOrderItemsMutation } = useMutation({
  //   mutationFn: approveOrderItemsMutationRequest,
  // });

  const { mutateAsync: cancelOrderItemsMutation } = useMutation({
    mutationFn: cancelOrderItemsMutationRequest,
  });

  const { mutateAsync: removeItemsFromOrderMutation } = useMutation({
    mutationFn: removeItemsFromOrderMutationRequest,
  });

  const closeCancelOrderItemsDialog = useCallback(() => {
    setShowCancelOrderItemsDialog(false);
  }, []);

  const closeManageOrderItemDialog = useCallback(() => {
    setShowManageOrderItemDialog(false);
  }, []);

  const closeManuallyAddProductDialog = useCallback(() => {
    setShowManuallyAddProductDialog(false);
  }, []);

  const exitedManageOrderItemDialog = useCallback(() => {
    setSelectedOrderItem(null);
  }, []);

  const openCancelOrderItemsDialog = useCallback(() => {
    setShowCancelOrderItemsDialog(true);
  }, []);

  const openManageOrderItemDialog = useCallback((item: OrderItem) => {
    setSelectedOrderItem(item);
    setShowManageOrderItemDialog(true);
  }, []);

  const openManuallyAddProductDialog = useCallback(() => {
    setShowManuallyAddProductDialog(true);
  }, []);

  const getProductByGtin = useCallback(
    async (gtin: string) => {
      const products = await queryClient.fetchQuery({
        ...getProductByGtinOptions(gtin),
        staleTime: 300000,
      });

      // TODO: handle multiple results.
      return (products || []).find((product) => product.sku);
    },
    [queryClient]
  );

  const getProductByScanDecode = useCallback(
    async (input: string) => {
      const products = await queryClient.fetchQuery({
        ...getProductByScanDecodeOptions(input),
        staleTime: 300000,
      });

      return (products || []).find((product) => product.sku);
    },
    [queryClient]
  );

  const addItemsToOrder = useCallback(
    async (
      input: Omit<AddItemsToOrderInput, 'orderId'> & { orderId?: string }
    ) => {
      const { items, orderId, requestOptions } = input || {};
      let currentOrderId = orderId || order?.id;

      if (!orderId && !order) {
        const createdOrder = await createOrder();
        currentOrderId = createdOrder.id;
      }

      if (!currentOrderId) {
        throw new Error('Order not found!');
      }

      let itemsInput = items;

      if (category) {
        itemsInput = itemsInput.map((itemInput) => ({
          ...itemInput,
          metaData: {
            ...(itemInput.metaData || {}),
            category: category.key,
          },
        }));
      }

      const response = await addItemsToOrderMutation({
        orderId: currentOrderId,
        items: itemsInput,
        requestOptions,
      });
      const updatedOrder = response?.addItemsToOrder?.order || null;

      setOrder(updatedOrder);
      return updatedOrder;
    },
    [addItemsToOrderMutation, category, createOrder, order, setOrder]
  );

  const addItemToOrderByGtin = useCallback(
    async (
      gtin: string,
      item: Omit<OrderItemInput, 'sku' | 'quantity'> & {
        quantity?: number;
      } = {},
      options: { beepEnabled?: boolean } = {}
    ) => {
      const { beepEnabled = false } = options || {};

      try {
        const product = await getProductByGtin(gtin);

        if (!product || !product?.sku) {
          throw new Error('Product not found!');
        }

        if (beepEnabled) {
          beepSuccess();
        }

        return addItemsToOrder({
          items: [
            {
              ...(item || {}),
              sku: product.sku,
              quantity: item?.quantity || 1,
              metaData: {
                ...(item?.metaData || {}),
                ...(item?.metaData?.category || category?.key
                  ? {
                      category: item?.metaData?.category || category?.key,
                    }
                  : {}),
              },
            },
          ],
        });
      } catch (error) {
        if (beepEnabled) {
          beepError();
        }

        return Promise.reject(error);
      }
    },
    [addItemsToOrder, category, getProductByGtin]
  );

  const addItemToOrderByScan = useCallback(
    async (input: string, options: { beepEnabled?: boolean } = {}) => {
      const { beepEnabled = false } = options || {};

      try {
        const product = await getProductByScanDecode(input);

        if (!product || !product?.sku) {
          throw new Error('Product not found!');
        }

        if (beepEnabled) {
          beepSuccess();
        }

        return addItemsToOrder({
          items: [
            {
              sku: product.sku,
              batchNumber: product.batchNumber || '',
              bestBefore: product.bestBefore || 0,
              rfid: product.rfid || '',
              serialNumber: product.serialNumber || '',
              weight: product.weight || 0,
              quantity: 1,
              ...(category
                ? {
                    metaData: {
                      category: category.key,
                    },
                  }
                : {}),
            },
          ],
        });
      } catch (error) {
        if (beepEnabled) {
          beepError();
        }

        return Promise.reject(error);
      }
    },
    [addItemsToOrder, category, getProductByScanDecode]
  );

  // const approveOrderItems = useCallback(
  //   async (
  //     input: Omit<ApproveOrderItemsInput, 'orderId'>,
  //     options: { skipUpdateOrder?: boolean } = {}
  //   ) => {
  //     const { itemIds } = input || {};
  //     const { skipUpdateOrder = false } = options || {};
  //
  //     const response = await approveOrderItemsMutation({
  //       orderId: order?.id || '',
  //       itemIds,
  //     });
  //     const updatedOrder = response?.approveOrderItems?.order || null;
  //
  //     if (!skipUpdateOrder) {
  //       setOrder(updatedOrder);
  //     }
  //
  //     return updatedOrder;
  //   },
  //   [approveOrderItemsMutation, order, setOrder]
  // );

  const cancelOrderItems = useCallback(
    async (
      input: Omit<CancelOrderItemsInput, 'orderId'>,
      options: { approveItems?: boolean; skipUpdateOrder?: boolean } = {}
    ) => {
      const { items } = input || {};
      const { skipUpdateOrder = false } = options || {};

      if (!order) {
        throw new Error('Order not provided');
      }

      await cancelOrderItemsMutation({
        orderId: order?.id || '',
        items,
      });
      const updatedOrder = await fetchOrder(order.id);

      if (!skipUpdateOrder && updatedOrder) {
        setOrder(updatedOrder);
      }

      return updatedOrder;
    },
    [cancelOrderItemsMutation, fetchOrder, order, setOrder]
  );

  const removeItemsFromOrder = useCallback(
    async ({ ids }: { ids: string[] }) => {
      const response = await removeItemsFromOrderMutation({
        orderId: order?.id || '',
        ids,
      });
      const updatedOrder = response?.removeItemsFromOrder?.order || null;

      setOrder(updatedOrder);
      return updatedOrder;
    },
    [order, removeItemsFromOrderMutation, setOrder]
  );

  const changeItemQuantityOnOrder = useCallback(
    async (input: ChangeItemQuantityOnOrderInput) => {
      const { item, quantity } = input || {};
      const [firstDetail] = item?.details || [];

      if (item.quantity === quantity) {
        return;
      }

      if (quantity > item.quantity) {
        return addItemsToOrder({
          items: [
            {
              adjustments: (item.adjustments || [])
                .filter((adjustment) => !adjustment?.outcome?.ruleId)
                .map((adjustment) => ({
                  id: adjustment.id,
                  description: adjustment.description,
                  amount: adjustment.amount,
                  isPercentage: adjustment.isPercentage,
                  isDiscount: adjustment.isDiscount,
                })),
              sku: item.sku,
              quantity: quantity - item.quantity,
              ...(firstDetail?.batchNumber
                ? { batchNumber: firstDetail.batchNumber }
                : {}),
              ...(firstDetail?.bestBefore
                ? { bestBefore: firstDetail.bestBefore }
                : {}),
              ...(firstDetail?.serialNumber
                ? { serialNumber: firstDetail.serialNumber }
                : {}),
              ...(firstDetail?.metaData
                ? { metaData: firstDetail.metaData }
                : {}),
            },
          ],
        });
      } else {
        const difference = item.quantity - quantity;
        const ids = [...(item.ids || [])].reverse().slice(0, difference);

        return removeItemsFromOrder({ ids });
      }
    },
    [addItemsToOrder, removeItemsFromOrder]
  );

  return {
    ...itemDiscount,
    addItemsToOrder,
    addItemToOrderByGtin,
    addItemToOrderByScan,
    cancelOrderItems,
    changeItemQuantityOnOrder,
    closeCancelOrderItemsDialog,
    closeManageOrderItemDialog,
    closeManuallyAddProductDialog,
    exitedManageOrderItemDialog,
    getProductByGtin,
    openCancelOrderItemsDialog,
    openManageOrderItemDialog,
    openManuallyAddProductDialog,
    removeItemsFromOrder,
    selectedOrderItem,
    showCancelOrderItemsDialog,
    showManageOrderItemDialog,
    showManuallyAddProductDialog,
  };
};
