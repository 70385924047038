import { useIntl } from 'react-intl';
import { createFileRoute } from '@tanstack/react-router';
import { SignIn } from '@afosto/auth-react';
import { Grid } from '@afosto/components';
import { translations } from './translations';

export const Route = createFileRoute('/auth/sign-in')({
  component: SignInComponent,
});

function SignInComponent() {
  const intl = useIntl();

  return (
    <Grid item xs={12} sm={8} md={6}>
      <SignIn
        defaultErrorMessage={intl.formatMessage(translations.signInFailed)}
        emailLabel={intl.formatMessage(translations.email)}
        forgotPasswordLabel={intl.formatMessage(translations.forgotPassword)}
        noAccountLabel={intl.formatMessage(translations.noAccount)}
        passwordLabel={intl.formatMessage(translations.password)}
        signInLabel={intl.formatMessage(translations.signIn)}
        signUpLabel={intl.formatMessage(translations.signUp)}
        staySignedInLabel={intl.formatMessage(translations.staySignedIn)}
        title={intl.formatMessage(translations.signInTitle)}
      />
    </Grid>
  );
}
