import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  changeChannel: {
    id: 'common.changeChannel',
    defaultMessage: 'Change channel',
    description: 'Common label for change channel',
  },
  channel: {
    id: 'common.channel',
    defaultMessage: 'Channel',
    description: 'Common label for channel',
  },
});
