import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  authorizationCode: {
    id: 'common.authorizationCode',
    defaultMessage: 'Authorization code',
    description: 'Common label for authorization code',
  },
  cardSerialNumber: {
    id: 'common.cardSerialNumber',
    defaultMessage: 'Card serial number',
    description: 'Common label for card serial number',
  },
  copyOfOrder: {
    id: 'common.copyOfOrder',
    defaultMessage: 'Copy of order',
    description: 'Common label for copy of order',
  },
  inputMethod: {
    id: 'common.inputMethod',
    defaultMessage: 'Input method',
    description: 'Common label for input method',
  },
  date: {
    id: 'common.date',
    defaultMessage: 'Date',
    description: 'Common label for date',
  },
  merchant: {
    id: 'common.merchant',
    defaultMessage: 'Merchant',
    description: 'Common label for merchant',
  },
  payment: {
    id: 'common.payment',
    defaultMessage: 'Payment',
    description: 'Common label for payment',
  },
  period: {
    id: 'common.period',
    defaultMessage: 'Period',
    description: 'Common label for period',
  },
  phoneNumberAbbreviation: {
    id: 'common.phoneNumberAbbreviation',
    defaultMessage: 'Tel',
    description: 'Common abbreviation for phone number',
  },
  subtotal: {
    id: 'common.subtotal',
    defaultMessage: 'Subtotal',
    description: 'Common label for subtotal',
  },
  terminal: {
    id: 'common.terminal',
    defaultMessage: 'Terminal',
    description: 'Common label for terminal',
  },
  total: {
    id: 'common.total',
    defaultMessage: 'Total',
    description: 'Common label for total',
  },
  transaction: {
    id: 'common.transaction',
    defaultMessage: 'Transaction',
    description: 'Common label for transaction',
  },
  vat: {
    id: 'common.vat',
    defaultMessage: 'VAT',
    description: 'Common label for vat',
  },
});
