import { lazy } from 'react';
import {
  createRootRouteWithContext,
  Outlet,
  // retainSearchParams,
} from '@tanstack/react-router';
import { useAuthentication } from '@afosto/auth-react';
// import { z } from 'zod';
// import { zodValidator } from '@tanstack/zod-adapter';

export interface RouterContext {
  auth?: ReturnType<typeof useAuthentication>;
}

const TanStackRouterDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null
    : lazy(() =>
        import('@tanstack/router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools,
        }))
      );

const TanStackQueryDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null
    : lazy(() =>
        import('@tanstack/react-query-devtools').then((res) => ({
          default: res.ReactQueryDevtools,
        }))
      );

// const searchSchema = z.object({
//   t: z.string().optional(),
// });

export const Route = createRootRouteWithContext<RouterContext>()({
  component: RootComponent,
  // TODO: fix later
  // validateSearch: zodValidator(searchSchema),
  // search: {
  //   middlewares: [retainSearchParams(['t'])],
  // },
});

function RootComponent() {
  return (
    <>
      <Outlet />
      <TanStackRouterDevtools />
      <TanStackQueryDevtools />
    </>
  );
}
