import { CardActionArea, styled } from '@afosto/components';
import { AngleRight } from '../../icons/regular';

export const ActionArea = styled(CardActionArea)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: ${(props) => props.theme.spacing(2)}
    ${(props) => props.theme.spacing(2.5)};
`;

export const Container = styled('div')`
  display: flex;
  align-items: center;
  padding: ${(props) => props.theme.spacing(2)}
    ${(props) => props.theme.spacing(2.5)};
`;

export const LabelContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing(2)};
`;

export const SelectIcon = styled(AngleRight)`
  color: ${(props) => props.theme.palette.gray[600]};
  font-size: 20px;
  margin-left: auto;
`;
