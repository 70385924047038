import { Box, Typography } from '@afosto/components';
import { OrderStateLabel } from '../OrderStateLabel';
import { Currency } from '../Currency';
import { Clock, LocationDot, User } from '../../icons/regular';
import { SearchResultsListItem } from '../SearchResultsListItem';
import { DateTime } from '../DateTime';
import type { OrderSearchResultListItemProps } from './types';

export const OrderSearchResultListItem = (
  props: OrderSearchResultListItemProps
) => {
  const { item } = props;

  return (
    <SearchResultsListItem {...props}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          columnGap: 5,
          rowGap: 1,
          gridTemplateAreas:
            "'orderNumber orderNumber total' 'customer address date'",
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1.5,
            gridArea: 'orderNumber',
          }}
        >
          <Typography variant="h6">{item.number}</Typography>
          <OrderStateLabel size="small" state={item.state} />
        </Box>
        <Box sx={{ gridArea: 'total', textAlign: 'right' }}>
          <Typography variant="h6">
            <Currency currency={item.currency} value={item.total} />
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
            gridArea: 'customer',
          }}
        >
          <User sx={{ color: (theme) => theme.palette.gray[700] }} />
          <Typography color="gray.800" component="span">
            {item.contact || '-'}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
            gridArea: 'address',
          }}
        >
          <LocationDot sx={{ color: (theme) => theme.palette.gray[700] }} />
          <Typography color="gray.800" component="span">
            {item.countryCode || '-'}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
            gridArea: 'date',
            justifyContent: 'flex-end',
          }}
        >
          <Clock sx={{ color: (theme) => theme.palette.gray[700] }} />
          <Typography color="gray.800" component="span">
            <DateTime value={item.createdAt} />
          </Typography>
        </Box>
      </Box>
    </SearchResultsListItem>
  );
};
