import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useAuthentication } from '@afosto/auth-react';
import { addQueryParamsToUrl } from '@afosto/utils';
import { usePrint } from '../../PrintProvider/hooks/usePrint';
import type { PrintCommand } from '../../PrintProvider/hooks/usePrintCommands';
import { translations } from '../translations';
import type { OrderPayment } from '../../../queries';
import {
  Adjustment,
  Channel,
  Invoice,
  InvoiceAdjustment,
  InvoiceItemAdjustment,
  InvoiceLine,
  Order,
  OrderItem,
  Vat,
} from '../../../types';

export interface UsePrintActionsOptions {
  channel: Channel | null;
  order: Order | null;
}

export interface PrintOrderInput {
  payments: OrderPayment[];
}

export interface PrintInvoiceInput {
  invoice: Invoice;
  payments: OrderPayment[];
}

export interface PrintInvoiceOptions {
  shouldStore?: boolean;
}

export const usePrintActions = (options: UsePrintActionsOptions) => {
  const { channel, order } = options || {};

  const { tenantId } = useAuthentication();
  const intl = useIntl();
  const { printCommands, printJob } = usePrint();
  const lastPrintedInvoiceStorageKey = `${tenantId}_lastPrintedInvoice`;

  const getLastPrintedInvoice = useCallback(() => {
    const lastPrintedInvoice =
      window.localStorage.getItem(lastPrintedInvoiceStorageKey) ?? '';

    try {
      if (lastPrintedInvoice) {
        return JSON.parse(lastPrintedInvoice);
      }

      return null;
    } catch {
      return null;
    }
  }, [lastPrintedInvoiceStorageKey]);

  const storeLastPrintedInvoice = useCallback(
    (commands: PrintCommand[]) => {
      window.localStorage.setItem(
        lastPrintedInvoiceStorageKey,
        JSON.stringify(btoa(JSON.stringify(commands)))
      );
    },
    [lastPrintedInvoiceStorageKey]
  );

  const formatCurrency = useCallback(
    (value: number, currency = 'EUR') => {
      return intl
        .formatNumber(value, {
          style: 'currency',
          currency,
          currencyDisplay: 'code',
        })
        .replace(currency, '')
        .trim();
    },
    [intl]
  );

  const openCashDrawer = useCallback(async () => {
    return printJob({
      commands: printCommands.peripheral(),
    });
  }, [printCommands, printJob]);

  const printOrder = useCallback(
    async (input?: PrintOrderInput) => {
      const { payments } = input || {};

      if (!channel) {
        throw new Error('Channel not found');
      }

      if (!order) {
        throw new Error('Order not found');
      }

      const pinPaymentsWithReceipt = (payments || []).filter(
        (payment) =>
          payment?.method?.code?.toLowerCase() === 'pin' &&
          !payment?.method?.isManual &&
          payment?.metadata
      );

      await printJob({
        commands: [
          ...printCommands.feed(),
          ...printCommands.feed(),
          // Heading
          ...(channel?.business
            ? printCommands.text(channel.business.name, { align: 'center' })
            : []),
          ...(channel?.business?.addressing.visiting
            ? printCommands.address(
                channel.business.addressing.visiting,
                channel.business.addressing.visiting.options?.format?.address ||
                  '',
                { align: 'center' }
              )
            : []),
          ...(channel?.business?.phoneNumber
            ? printCommands.text(
                `${intl.formatMessage(translations.phoneNumberAbbreviation)}: ${
                  channel.business.phoneNumber.number
                }`,
                { align: 'center' }
              )
            : []),
          ...printCommands.feed(),
          ...printCommands.line(),
          ...printCommands.feed(),
          // Copy order text
          ...printCommands.text(
            intl.formatMessage(translations.copyOfOrder)?.toUpperCase(),
            {
              align: 'center',
              style: 'bold',
            }
          ),
          ...printCommands.text(order.number, {
            align: 'center',
          }),
          ...printCommands.line(),
          ...printCommands.feed(),
          // Items
          ...(order?.items || []).flatMap((item: OrderItem) => {
            const [firstDetail] = item.details || [];

            return [
              ...printCommands.table([...printCommands.text(item.label)]),
              // Quantity row
              ...printCommands.table([
                ...printCommands.text(
                  `  ${item.quantity}x ${formatCurrency(
                    (firstDetail?.pricing?.amount || 0) / 100
                  )}`,
                  { width: 22 }
                ),
                ...printCommands.text(formatCurrency(item.subtotal / 100), {
                  align: 'right',
                  width: 10,
                }),
                ...printCommands.text(
                  !item.adjustments?.length
                    ? formatCurrency(item.total / 100)
                    : '',
                  {
                    align: 'right',
                    width: 10,
                  }
                ),
              ]),
              // Adjustments
              ...((item.adjustments || []).filter(
                (adjustment) => adjustment.outcome?.amount > 0
              ).length > 0
                ? [
                    ...item.adjustments.flatMap((adjustment: Adjustment, idx) =>
                      printCommands.table([
                        ...printCommands.text(
                          `  ${adjustment.description}${
                            adjustment.isPercentage
                              ? ` ${adjustment.amount}%`
                              : ''
                          }`,
                          {
                            width: 22,
                          }
                        ),
                        ...printCommands.text(
                          formatCurrency(
                            ((adjustment.outcome?.amount || 0) *
                              (adjustment.isDiscount ? -1 : 1)) /
                              100
                          ),
                          { align: 'right', width: 10 }
                        ),
                        ...printCommands.text(
                          idx + 1 === item.adjustments.length
                            ? formatCurrency(item.total / 100)
                            : '',
                          { align: 'right', width: 10 }
                        ),
                      ])
                    ),
                  ]
                : []),
              ...printCommands.feed(),
            ];
          }),
          ...printCommands.line(),
          ...printCommands.feed(),
          // Subtotal
          ...printCommands.table([
            ...printCommands.text(intl.formatMessage(translations.subtotal), {
              width: 32,
            }),
            ...printCommands.text(
              formatCurrency(order.subtotal / 100, order.currency),
              {
                align: 'right',
                width: 10,
              }
            ),
          ]),
          // Adjustments
          ...((order.adjustments || []).filter(
            (adjustment) => adjustment.outcome?.amount > 0
          ).length > 0
            ? [
                ...order.adjustments.flatMap((adjustment: Adjustment) =>
                  printCommands.table([
                    ...printCommands.text(
                      `${adjustment.description}${
                        adjustment.isPercentage ? ` ${adjustment.amount}%` : ''
                      }`,
                      {
                        width: 32,
                      }
                    ),
                    ...printCommands.text(
                      formatCurrency(
                        ((adjustment.outcome?.amount || 0) *
                          (adjustment.isDiscount ? -1 : 1)) /
                          100
                      ),
                      { align: 'right', width: 10 }
                    ),
                  ])
                ),
              ]
            : []),
          // Total
          ...printCommands.table([
            ...printCommands.text(intl.formatMessage(translations.total), {
              style: 'bold',
              width: 32,
            }),
            ...printCommands.text(
              formatCurrency(order.total / 100, order.currency),
              {
                align: 'right',
                style: 'bold',
                width: 10,
              }
            ),
          ]),
          // Payments
          ...(payments || []).flatMap((payment) =>
            printCommands.table([
              ...printCommands.text(payment.method?.name || '', {
                width: 32,
              }),
              ...printCommands.text(formatCurrency(payment.amountPaid / 100), {
                align: 'right',
                width: 10,
              }),
            ])
          ),
          // VAT
          ...((order?.vat || []).length > 0
            ? [
                ...printCommands.feed(),
                ...order.vat.flatMap((vat: Vat) =>
                  printCommands.table([
                    ...printCommands.text(
                      intl.formatMessage(translations.vat),
                      { width: 22 }
                    ),
                    ...printCommands.text(`${vat.rate}%`, {
                      align: 'right',
                      width: 10,
                    }),
                    ...printCommands.text(
                      formatCurrency(vat.amount / 100, order.currency),
                      { align: 'right', width: 10 }
                    ),
                  ])
                ),
              ]
            : []),
          // PIN receipt
          ...pinPaymentsWithReceipt.flatMap((payment) => [
            ...printCommands.feed(),
            ...printCommands.line(),
            ...printCommands.feed(),
            ...printCommands.table([
              ...printCommands.text(intl.formatMessage(translations.terminal), {
                width: 1,
              }),
              ...printCommands.text(payment.metadata?.terminal || '-', {
                align: 'right',
                width: 1,
              }),
            ]),
            ...printCommands.table([
              ...printCommands.text(intl.formatMessage(translations.merchant), {
                width: 1,
              }),
              ...printCommands.text(payment.metadata?.merchant || '-', {
                align: 'right',
                width: 1,
              }),
            ]),
            ...printCommands.table([
              ...printCommands.text(intl.formatMessage(translations.period), {
                width: 1,
              }),
              ...printCommands.text(payment.metadata?.period || '-', {
                align: 'right',
                width: 1,
              }),
            ]),
            ...printCommands.table([
              ...printCommands.text(
                intl.formatMessage(translations.transaction),
                { width: 1 }
              ),
              ...printCommands.text(payment.metadata?.transaction || '-', {
                align: 'right',
                width: 1,
              }),
            ]),
            ...(payment.metadata?.cardBrandName
              ? printCommands.table([
                  ...printCommands.text(payment.metadata.cardBrandName, {
                    width: 1,
                  }),
                ])
              : []),
            ...(payment.metadata?.cardSerialNumber
              ? printCommands.table([
                  ...printCommands.text(
                    intl.formatMessage(translations.cardSerialNumber),
                    { width: 1 }
                  ),
                  ...printCommands.text(payment.metadata.cardSerialNumber, {
                    align: 'right',
                    width: 1,
                  }),
                ])
              : []),
            ...printCommands.feed(),
            ...printCommands.table([
              ...printCommands.text(
                intl.formatMessage(translations.payment).toUpperCase(),
                { width: 1 }
              ),
            ]),
            ...printCommands.table([
              ...printCommands.text(intl.formatMessage(translations.date), {
                width: 1,
              }),
              ...printCommands.text(
                intl.formatDate(payment.paidAt, {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                }),
                { align: 'right', width: 1 }
              ),
            ]),
            ...(payment.metadata?.authorizationCode
              ? printCommands.table([
                  ...printCommands.text(
                    intl.formatMessage(translations.authorizationCode),
                    { width: 1 }
                  ),
                  ...printCommands.text(payment.metadata.authorizationCode, {
                    align: 'right',
                    width: 1,
                  }),
                ])
              : []),
            ...printCommands.table([
              ...printCommands.text(intl.formatMessage(translations.total), {
                width: 1,
              }),
              ...printCommands.text(formatCurrency(payment.amountPaid || 0), {
                align: 'right',
                width: 1,
              }),
            ]),
            ...(payment.metadata?.input
              ? printCommands.table([
                  ...printCommands.text(
                    intl.formatMessage(translations.inputMethod),
                    { width: 1 }
                  ),
                  ...printCommands.text(payment.metadata.input, {
                    align: 'right',
                    width: 1,
                  }),
                ])
              : []),
            ...printCommands.feed(),
          ]),
          ...printCommands.line(),
          ...printCommands.feed(),
          // Footer
          ...printCommands.text(order.number, { align: 'center' }),
          ...printCommands.text(
            intl.formatDate(order.createdAt, {
              dateStyle: 'full',
              timeStyle: 'medium',
            }),
            { align: 'center' }
          ),
          ...printCommands.text(channel.name, { align: 'center' }),
          ...printCommands.feed(),
          // Barcode
          ...printCommands.line(),
          ...printCommands.feed(),
          ...printCommands.barcode(order.number, {
            align: 'center',
            height: 50,
          }),
          ...printCommands.feed(),
          ...printCommands.cut(),
          ...(channel.logo
            ? printCommands.image(
                addQueryParamsToUrl(channel.logo, { w: 400 }),
                { align: 'center' }
              )
            : []),
        ],
      });
    },
    [channel, formatCurrency, intl, order, printCommands, printJob]
  );

  const printInvoice = useCallback(
    async (input?: PrintInvoiceInput, options?: PrintInvoiceOptions) => {
      const { invoice, payments } = input || {};
      const { shouldStore = true } = options || {};

      if (!channel) {
        throw new Error('Channel not found');
      }

      if (!invoice) {
        throw new Error('Invoice not found');
      }

      const pinPaymentsWithReceipt = (payments || []).filter(
        (payment) =>
          payment?.method?.code?.toLowerCase() === 'pin' &&
          !payment?.method?.isManual &&
          payment?.metadata
      );

      const commands = [
        ...printCommands.feed(),
        ...printCommands.feed(),
        // Heading
        ...(channel?.business
          ? printCommands.text(channel.business.name, { align: 'center' })
          : []),
        ...(channel?.business?.addressing.visiting
          ? printCommands.address(
              channel.business.addressing.visiting,
              channel.business.addressing.visiting.options?.format?.address ||
                '',
              { align: 'center' }
            )
          : []),
        ...(channel?.business?.phoneNumber
          ? printCommands.text(
              `${intl.formatMessage(translations.phoneNumberAbbreviation)}: ${
                channel.business.phoneNumber.number
              }`,
              { align: 'center' }
            )
          : []),
        ...printCommands.feed(),
        ...printCommands.line(),
        ...printCommands.feed(),
        // Items
        ...(invoice?.lines || []).flatMap((line: InvoiceLine) => {
          // const [firstDetail] = item.details || [];

          return [
            ...printCommands.table([...printCommands.text(line.label)]),
            // Quantity row
            ...printCommands.table([
              ...printCommands.text(
                `  ${line.quantity}x ${formatCurrency(
                  (line.debit?.amount || 0) / 100
                )}`,
                { width: 22 }
              ),
              ...printCommands.text(
                formatCurrency(line.debit?.subtotal / 100),
                {
                  align: 'right',
                  width: 10,
                }
              ),
              ...printCommands.text(
                !line.debit?.adjustments?.length
                  ? formatCurrency(line.total / 100)
                  : '',
                {
                  align: 'right',
                  width: 10,
                }
              ),
            ]),
            // Adjustments
            ...((line.debit?.adjustments || []).filter(
              (adjustment) => adjustment?.amount > 0
            ).length > 0
              ? [
                  ...(line.debit?.adjustments || []).flatMap(
                    (adjustment: InvoiceItemAdjustment, idx) =>
                      printCommands.table([
                        ...printCommands.text(`  ${adjustment.description}`, {
                          width: 22,
                        }),
                        ...printCommands.text(
                          formatCurrency(
                            ((adjustment?.amount || 0) *
                              (adjustment.isDiscount ? -1 : 1)) /
                              100
                          ),
                          { align: 'right', width: 10 }
                        ),
                        ...printCommands.text(
                          idx + 1 === line.debit.adjustments.length
                            ? formatCurrency(line.total / 100)
                            : '',
                          { align: 'right', width: 10 }
                        ),
                      ])
                  ),
                ]
              : []),
            ...printCommands.feed(),
          ];
        }),
        ...printCommands.line(),
        ...printCommands.feed(),
        // Subtotal
        ...printCommands.table([
          ...printCommands.text(intl.formatMessage(translations.subtotal), {
            width: 32,
          }),
          ...printCommands.text(
            formatCurrency(invoice.subtotal / 100, invoice.currency),
            {
              align: 'right',
              width: 10,
            }
          ),
        ]),
        // Adjustments
        ...((invoice.adjustments || []).filter(
          (adjustment: InvoiceAdjustment) => adjustment?.amount > 0
        ).length > 0
          ? [
              ...invoice.adjustments.flatMap((adjustment: InvoiceAdjustment) =>
                printCommands.table([
                  ...printCommands.text(`${adjustment.description}`, {
                    width: 32,
                  }),
                  ...printCommands.text(
                    formatCurrency(
                      ((adjustment?.amount || 0) *
                        (adjustment.isDiscount ? -1 : 1)) /
                        100
                    ),
                    { align: 'right', width: 10 }
                  ),
                ])
              ),
            ]
          : []),
        // Total
        ...printCommands.table([
          ...printCommands.text(intl.formatMessage(translations.total), {
            style: 'bold',
            width: 32,
          }),
          ...printCommands.text(
            formatCurrency(invoice.total / 100, invoice.currency),
            {
              align: 'right',
              style: 'bold',
              width: 10,
            }
          ),
        ]),
        // Payments
        ...(payments || []).flatMap((payment) =>
          printCommands.table([
            ...printCommands.text(payment.method?.name || '', {
              width: 32,
            }),
            ...printCommands.text(formatCurrency(payment.amountPaid / 100), {
              align: 'right',
              width: 10,
            }),
          ])
        ),
        // VAT
        ...((invoice?.vat || []).length > 0
          ? [
              ...printCommands.feed(),
              ...invoice.vat.flatMap((vat: Vat) =>
                printCommands.table([
                  ...printCommands.text(intl.formatMessage(translations.vat), {
                    width: 22,
                  }),
                  ...printCommands.text(`${vat.rate}%`, {
                    align: 'right',
                    width: 10,
                  }),
                  ...printCommands.text(
                    formatCurrency(vat.amount / 100, invoice.currency),
                    { align: 'right', width: 10 }
                  ),
                ])
              ),
            ]
          : []),
        // PIN receipt
        ...pinPaymentsWithReceipt.flatMap((payment) => [
          ...printCommands.feed(),
          ...printCommands.line(),
          ...printCommands.feed(),
          ...printCommands.table([
            ...printCommands.text(intl.formatMessage(translations.terminal), {
              width: 1,
            }),
            ...printCommands.text(payment.metadata?.terminal || '-', {
              align: 'right',
              width: 1,
            }),
          ]),
          ...printCommands.table([
            ...printCommands.text(intl.formatMessage(translations.merchant), {
              width: 1,
            }),
            ...printCommands.text(payment.metadata?.merchant || '-', {
              align: 'right',
              width: 1,
            }),
          ]),
          ...printCommands.table([
            ...printCommands.text(intl.formatMessage(translations.period), {
              width: 1,
            }),
            ...printCommands.text(payment.metadata?.period || '-', {
              align: 'right',
              width: 1,
            }),
          ]),
          ...printCommands.table([
            ...printCommands.text(
              intl.formatMessage(translations.transaction),
              { width: 1 }
            ),
            ...printCommands.text(payment.metadata?.transaction || '-', {
              align: 'right',
              width: 1,
            }),
          ]),
          ...(payment.metadata?.cardBrandName
            ? printCommands.table([
                ...printCommands.text(payment.metadata.cardBrandName, {
                  width: 1,
                }),
              ])
            : []),
          ...(payment.metadata?.cardSerialNumber
            ? printCommands.table([
                ...printCommands.text(
                  intl.formatMessage(translations.cardSerialNumber),
                  { width: 1 }
                ),
                ...printCommands.text(payment.metadata.cardSerialNumber, {
                  align: 'right',
                  width: 1,
                }),
              ])
            : []),
          ...printCommands.feed(),
          ...printCommands.table([
            ...printCommands.text(
              intl.formatMessage(translations.payment).toUpperCase(),
              { width: 1 }
            ),
          ]),
          ...printCommands.table([
            ...printCommands.text(intl.formatMessage(translations.date), {
              width: 1,
            }),
            ...printCommands.text(
              intl.formatDate(payment.paidAt, {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              }),
              { align: 'right', width: 1 }
            ),
          ]),
          ...(payment.metadata?.authorizationCode
            ? printCommands.table([
                ...printCommands.text(
                  intl.formatMessage(translations.authorizationCode),
                  { width: 1 }
                ),
                ...printCommands.text(payment.metadata.authorizationCode, {
                  align: 'right',
                  width: 1,
                }),
              ])
            : []),
          ...printCommands.table([
            ...printCommands.text(intl.formatMessage(translations.total), {
              width: 1,
            }),
            ...printCommands.text(formatCurrency(payment.amountPaid || 0), {
              align: 'right',
              width: 1,
            }),
          ]),
          ...(payment.metadata?.input
            ? printCommands.table([
                ...printCommands.text(
                  intl.formatMessage(translations.inputMethod),
                  { width: 1 }
                ),
                ...printCommands.text(payment.metadata.input, {
                  align: 'right',
                  width: 1,
                }),
              ])
            : []),
          ...printCommands.feed(),
        ]),
        ...printCommands.line(),
        ...printCommands.feed(),
        // Footer
        ...printCommands.text(invoice.number, { align: 'center' }),
        ...printCommands.text(
          intl.formatDate(undefined, {
            dateStyle: 'full',
            timeStyle: 'medium',
          }),
          { align: 'center' }
        ),
        ...printCommands.text(channel.name, { align: 'center' }),
        ...printCommands.feed(),
        // Barcode
        ...(order
          ? [
              ...printCommands.line(),
              ...printCommands.feed(),
              ...printCommands.barcode(order.number, {
                align: 'center',
                height: 50,
              }),
            ]
          : []),
        ...printCommands.feed(),
        ...printCommands.cut(),
        ...(channel.logo
          ? printCommands.image(addQueryParamsToUrl(channel.logo, { w: 400 }), {
              align: 'center',
            })
          : []),
      ];

      await printJob({
        commands,
      });

      if (shouldStore) {
        storeLastPrintedInvoice(commands);
      }
    },
    [
      channel,
      formatCurrency,
      intl,
      order,
      printCommands,
      printJob,
      storeLastPrintedInvoice,
    ]
  );

  const printLastPrintedInvoice = useCallback(async () => {
    const lastPrintedInvoice = getLastPrintedInvoice();

    if (lastPrintedInvoice) {
      try {
        const commands = JSON.parse(atob(lastPrintedInvoice));

        return printJob({
          commands,
        });
      } catch {
        // TODO: Do something with feedback
      }
    }
  }, [getLastPrintedInvoice, printJob]);

  return {
    openCashDrawer,
    printInvoice,
    printLastPrintedInvoice,
    printOrder,
  };
};
