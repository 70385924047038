import { gql } from '@afosto/graphql-client';
import { CoreAddressFragment } from './AddressFragment';
import { CorePhoneNumberFragment } from './PhoneNumberFragment';

export const CoreChannelFragment = gql`
  ${CoreAddressFragment}
  ${CorePhoneNumberFragment}
  fragment CoreChannelFragment on Channel {
    id
    locale
    logo
    name
    type
    abacus {
      order {
        prefix
      }
    }
    business {
      id
      name
      addressing {
        visiting {
          ...CoreAddressFragment
        }
      }
      phone_number {
        ...CorePhoneNumberFragment
      }
    }
    options {
      is_invoicing_enabled
    }
  }
`;
