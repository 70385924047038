import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from '@afosto/components';
import { FullscreenDialog } from '../FullscreenDialog';
import { AccountActionsSection } from './components/AccountActionsSection';
import { ChannelActionsSection } from './components/ChannelActionsSection';
import { ConnectivityActionsSection } from './components/ConnectivityActionsSection';
import { GeneralActionsSection } from './components/GeneralActionsSection';
import { translations } from './translations';
import type { ActionsDialogProps } from './types';

export const ActionsDialog = (props: ActionsDialogProps) => {
  const { onClose, ...otherProps } = props;

  const intl = useIntl();

  const handleClose = () => {
    if (onClose && typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    <FullscreenDialog
      {...otherProps}
      onClose={handleClose}
      title={intl.formatMessage(translations.title)}
      topBarProps={{
        actions: (
          <Link
            component="button"
            onClick={handleClose}
            fontWeight={500}
            type="button"
            variant="bodyLarge"
          >
            <FormattedMessage {...translations.close} />
          </Link>
        ),
      }}
    >
      <GeneralActionsSection />
      <ChannelActionsSection />
      <ConnectivityActionsSection />
      <AccountActionsSection />
    </FullscreenDialog>
  );
};
