import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from '@afosto/components';
import { FullscreenDialog } from '../FullscreenDialog';
import { ChannelsList } from '../ChannelsList';
import { useChannel } from '../ChannelProvider/hooks/useChannel';
import { translations } from './translations';
import type { SelectChannelDialogProps } from './types';

export const SelectChannelDialog = (props: SelectChannelDialogProps) => {
  const {
    appear = true,
    onClose,
    onExited,
    onSelectChannel,
    ...otherProps
  } = props;

  const intl = useIntl();
  const { channelOptions } = useChannel();

  const handleClose = () => {
    if (onClose && typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    <FullscreenDialog
      {...otherProps}
      description={intl.formatMessage(translations.description)}
      onClose={onClose ? handleClose : undefined}
      title={intl.formatMessage(translations.title)}
      topBarProps={{
        actions: onClose && (
          <Link
            component="button"
            onClick={handleClose}
            fontWeight={500}
            type="button"
            variant="bodyLarge"
          >
            <FormattedMessage {...translations.close} />
          </Link>
        ),
      }}
      TransitionProps={{ appear, onExited }}
    >
      <ChannelsList
        channels={channelOptions}
        onSelectChannel={onSelectChannel}
      />
    </FullscreenDialog>
  );
};
