import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';
import { AuthenticationLayout } from '@afosto/auth-react';

export const Route = createFileRoute('/auth')({
  beforeLoad: ({ context }) => {
    if (context.auth?.isAuthenticated) {
      throw redirect({
        to: '/',
      });
    }
  },
  component: RouteComponent,
});

function RouteComponent() {
  return (
    <AuthenticationLayout>
      <Outlet />
    </AuthenticationLayout>
  );
}
