import { FormattedMessage } from 'react-intl';
import { createFileRoute, Outlet, useLocation } from '@tanstack/react-router';
import { MenuItem, Typography } from '@afosto/components';
import Link from '../../../components/Link';
import { translations } from './translations';
import * as Styled from './layout.styles';

export const Route = createFileRoute('/_protected/orders')({
  component: OrdersLayoutComponent,
});

function OrdersLayoutComponent() {
  const { pathname } = useLocation();

  return (
    <Styled.Container>
      <Styled.LeftLayout>
        <Styled.TitleHeader>
          <Typography variant="h5">Orders</Typography>
        </Styled.TitleHeader>
        <Styled.MenuList>
          <MenuItem
            component={Link}
            href="/orders"
            selected={pathname === '/orders'}
          >
            <FormattedMessage {...translations.allOrders} />
          </MenuItem>
          <MenuItem
            component={Link}
            href="/orders/concept"
            selected={pathname === '/orders/concept'}
          >
            <FormattedMessage {...translations.concept} />
          </MenuItem>
          <MenuItem
            component={Link}
            href="/orders/open"
            selected={pathname === '/orders/open'}
          >
            <FormattedMessage {...translations.open} />
          </MenuItem>
          <MenuItem
            component={Link}
            href="/orders/closed"
            selected={pathname === '/orders/closed'}
          >
            <FormattedMessage {...translations.closed} />
          </MenuItem>
        </Styled.MenuList>
      </Styled.LeftLayout>
      <Outlet />
    </Styled.Container>
  );
}
