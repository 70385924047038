import { createFileRoute } from '@tanstack/react-router';
import { OrdersSearch } from '../../../components/OrdersSearch';

export const Route = createFileRoute('/_protected/orders/')({
  component: OrdersComponent,
});

function OrdersComponent() {
  return <OrdersSearch />;
}
