import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  addPrinter: {
    id: 'common.addPrinter',
    defaultMessage: 'Add printer',
    description: 'Common label for add printer',
  },
  connectivity: {
    id: 'common.connectivity',
    defaultMessage: 'Connectivity',
    description: 'Common label for connectivity',
  },
  printerIsOffline: {
    id: 'common.printerIsOffline',
    defaultMessage: 'Printer is offline',
    description: 'Common label for printer is offline',
  },
});
