import { createFileRoute } from '@tanstack/react-router';
import { AuthenticationCallback } from '@afosto/auth-react';

export const Route = createFileRoute('/auth_/callback')({
  component: AuthCallbackComponent,
});

function AuthCallbackComponent() {
  return <AuthenticationCallback />;
}
