import {
  type ChangeEvent,
  type MouseEvent,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import {
  Box,
  IconButton,
  InputAdornment,
  InputGroup,
  InputGroupAdornment,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@afosto/components';
import { useAsyncDebounce } from '@afosto/hooks';
import { addQueryParamsToUrl } from '@afosto/utils';
import { useQuery } from '@tanstack/react-query';
import { useApp } from '../AppProvider/hooks/useApp';
import { useChannel } from '../ChannelProvider/hooks/useChannel';
import { useOrder } from '../OrderProvider/hooks/useOrder';
import { OrderSearchResultListItem } from '../OrderSearchResultListItem';
import { SearchResults } from '../SearchResults';
import { Times } from '../../icons/light';
import { LongArrowLeft } from '../../icons/solid';
import {
  searchContact,
  searchOrder,
  searchOrganisation,
  searchProductSuggest,
} from '../../queries';
import { SEARCH_TYPES } from './constants';
import { getFullName } from '../../utils';
import { translations } from './translations';
import type { SearchType } from '../AppProvider/hooks/useSearch';
import type {
  ContactSearchResultItem,
  ExtendedSearchResultItem,
  OrderSearchResultItem,
  OrganisationSearchResultItem,
  ProductSearchResultItem,
} from './types';
import type { Contact, OrderState } from '../../types';

export const Search = () => {
  const intl = useIntl();
  const { closeSearch, searchType, setSearchType } = useApp();
  const { channel } = useChannel();
  const { addCustomerToOrder, addItemsToOrder, loadOrder } = useOrder();

  const inputRef = useRef<HTMLInputElement | null>(null);
  const [searchInput, setSearchInput] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  useLayoutEffect(() => {
    inputRef.current?.focus();
  }, []);

  const startSearch = useAsyncDebounce((value: string) => {
    setSearchQuery(value);
  }, 250);

  const searchTypeOptions = SEARCH_TYPES.map((type: SearchType) => {
    const typeTranslationKey: keyof typeof translations = type;

    return {
      label: intl.formatMessage(translations[typeTranslationKey]),
      value: type,
    };
  });

  const handleChangeSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value || '';

    setSearchInput(searchValue);
    startSearch(searchValue);
  };

  const handleClearSearchInput = () => {
    setSearchInput('');
    setSearchQuery('');
  };

  const handleSwitchType = (event: MouseEvent<HTMLElement>, value: unknown) => {
    if (value) {
      setSearchType(value as SearchType);
    }
  };

  const { data: productsResponse, isFetching: isFetchingProducts } = useQuery({
    ...searchProductSuggest(searchQuery),
    enabled: searchType === 'products' && searchQuery?.length > 0,
  });
  const productResults: ProductSearchResultItem[] = (
    productsResponse || []
  ).map((item) => {
    let [firstImageUrl] = item?.image || [];

    if (firstImageUrl?.startsWith('https://cdn.quicq.io')) {
      firstImageUrl = addQueryParamsToUrl(firstImageUrl, {
        h: 40,
        w: 40,
      });
    }

    return {
      ...item,
      avatar: firstImageUrl || '',
      description: item.sku || '',
      label: item.label || item.sku,
      value: item.id,
    };
  });

  const { data: ordersResponse, isFetching: isFetchingOrders } = useQuery({
    ...searchOrder(searchQuery, {
      ...(channel ? { channel } : {}),
    }),
    enabled: searchType === 'orders' && searchQuery?.length > 0,
  });
  const orderResults: OrderSearchResultItem[] = (ordersResponse || []).map(
    (item) => ({
      ...item,
      createdAt: item.createdAt as number,
      currency: item.currency as string,
      number: item.number as string,
      description: item.number as string,
      label: item.number as string,
      state: item.state as OrderState,
      total: item.total as number,
      value: item.id,
    })
  );

  const { data: contactsResponse, isFetching: isFetchingContacts } = useQuery({
    ...searchContact(searchQuery),
    enabled: searchType === 'contacts' && searchQuery?.length > 0,
  });
  const contactResults: ContactSearchResultItem[] = (
    contactsResponse || []
  ).map((item) => ({
    ...item,
    description: (item.email || '') as string,
    label: getFullName({
      givenName: item.givenName,
      additionalName: item.additionalName,
      familyName: item.familyName,
    } as Contact),
    value: item.id,
  }));

  const { data: organisationsResponse, isFetching: isFetchingOrganisations } =
    useQuery({
      ...searchOrganisation(searchQuery),
      enabled: searchType === 'organisations' && searchQuery?.length > 0,
    });
  const organisationResults: OrganisationSearchResultItem[] = (
    organisationsResponse || []
  ).map((item) => ({
    ...item,
    description: (item.email || '') as string,
    label: (item.name || '') as string,
    value: item.id,
  }));

  const isSearching =
    isFetchingProducts ||
    isFetchingOrders ||
    isFetchingContacts ||
    isFetchingOrganisations;
  let searchResults: ExtendedSearchResultItem[] = [];

  if (searchType === 'products') {
    searchResults = productResults;
  } else if (searchType === 'orders') {
    searchResults = orderResults;
  } else if (searchType === 'contacts') {
    searchResults = contactResults;
  } else if (searchType === 'organisations') {
    searchResults = organisationResults;
  }

  const handleSelectResult = (result: ExtendedSearchResultItem) => async () => {
    try {
      if (result && searchType === 'products' && result.sku) {
        const { sku } = result as ProductSearchResultItem;

        await addItemsToOrder({
          items: [
            {
              sku,
              quantity: 1,
            },
          ],
        });
      } else if (result && ['contacts', 'organisations'].includes(searchType)) {
        await addCustomerToOrder({
          customer:
            searchType === 'organisations'
              ? {
                  organisationId: result.value,
                }
              : { contactId: result.value },
        });
      } else if (result && searchType === 'orders' && result.id) {
        await loadOrder(result.id);
      }

      closeSearch();
    } catch {
      //TODO: do something with error.
    }
  };

  return (
    <Box
      sx={{
        overflow: 'hidden',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <InputGroup
        sx={{
          flexShrink: 0,
          height: 56,
          '.AfInputGroupAdornment-root': {
            borderColor: (theme) => theme.palette.divider,
            borderRadius: 0,
            borderLeft: 0,
            borderTop: 0,
            p: 0,
          },
        }}
        fullWidth
      >
        <InputGroupAdornment>
          <IconButton
            onClick={closeSearch}
            size="large"
            variant="minimalDark2"
            sx={{
              height: '100%',
              '&.size-sizeLarge': {
                px: `16px!important`,
              },
            }}
          >
            <LongArrowLeft />
          </IconButton>
        </InputGroupAdornment>
        <TextField
          inputRef={inputRef}
          InputProps={{
            endAdornment: searchInput ? (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClearSearchInput}
                  variant="minimalLight2"
                  size="large"
                >
                  <Times />
                </IconButton>
              </InputAdornment>
            ) : undefined,
          }}
          onChange={handleChangeSearchInput}
          placeholder="Search..."
          value={searchInput}
          sx={(theme) => ({
            '.MuiInputBase-root': {
              backgroundColor: theme.palette.gray[50],
              borderColor: theme.palette.divider,
              borderRadius: 0,
              borderRight: 0,
              borderTop: 0,
              color: theme.palette.gray[800],
              height: '100%',
            },
          })}
          fullWidth
        />
      </InputGroup>
      <Box
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          p: 2.5,
        }}
      >
        <ToggleButtonGroup
          onChange={handleSwitchType}
          size="large"
          value={searchType}
          exclusive
          fullWidth
        >
          {searchTypeOptions.map((searchTypeOption) => (
            <ToggleButton
              key={searchTypeOption.value}
              size="large"
              value={searchTypeOption.value}
              sx={{ px: 3, py: '15px' }}
            >
              {searchTypeOption.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>
      <SearchResults
        isSearching={isSearching}
        onSelectSearchResult={handleSelectResult}
        searchResults={searchResults}
        SearchResultsListItemComponent={
          searchType === 'orders' ? OrderSearchResultListItem : undefined
        }
        searchQuery={searchQuery}
      />
    </Box>
  );
};
