import apiClient from '@afosto/api-client';
import { Scanner } from '@afosto/lookup-service';
import { queryOptions } from '@tanstack/react-query';
import type { LookupProduct, LookupScanDecodeResponseItem } from '../types';

export const GET_PRODUCT_BY_SCAN_DECODE_QUERY = 'getProductByScanDecode';

export const fetchProductByScanDecode = async ({
  queryKey,
}: {
  queryKey: [string, string, object];
}): Promise<LookupProduct[]> => {
  const [, input, requestOptions] = queryKey;
  const scannerApi = new Scanner(apiClient);
  const response = await scannerApi.decodeScanInput(
    { filterInputEq: btoa(input) },
    requestOptions
  );
  return (response?.data?.data || []).map(
    (data: LookupScanDecodeResponseItem) => {
      const { sku, gtin } = data || {};
      const specifications = data?.specifications?.at(0) || {};

      return {
        ...specifications,
        gtin,
        sku,
      };
    }
  );
};

export const getProductByScanDecode = (input: string, requestOptions = {}) => {
  return queryOptions({
    queryKey: [GET_PRODUCT_BY_SCAN_DECODE_QUERY, input, requestOptions],
    queryFn: fetchProductByScanDecode,
  });
};
