import { useIntl } from 'react-intl';
import { useLocation } from '@tanstack/react-router';
import { BottomNavigationAction, Paper } from '@afosto/components';
import Link from '../Link';
import { Home, Receipt, Tag, User } from '../../icons/solid';
import * as Styled from './BottomNavigation.styles';
import { translations } from './translations';

export const BottomNavigation = () => {
  const intl = useIntl();
  const { pathname } = useLocation();

  return (
    <Paper>
      <Styled.BottomNavigationContainer showLabels value={pathname}>
        <BottomNavigationAction
          component={Link}
          href="/"
          label={intl.formatMessage(translations.homePage)}
          value="/"
          icon={<Home />}
        />
        <BottomNavigationAction
          component={Link}
          href="/orders"
          label={intl.formatMessage(translations.orders)}
          value="/orders"
          icon={<Receipt />}
        />
        <BottomNavigationAction
          label={intl.formatMessage(translations.products)}
          value="/products"
          icon={<Tag />}
        />
        <BottomNavigationAction
          label={intl.formatMessage(translations.contacts)}
          value="/customers"
          icon={<User />}
        />
        <BottomNavigationAction
          label={intl.formatMessage(translations.organisations)}
          value="/customers"
          icon={<User />}
        />
      </Styled.BottomNavigationContainer>
    </Paper>
  );
};
