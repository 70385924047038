import { ChannelContext } from './ChannelContext';
import { SelectChannelDialog } from '../SelectChannelDialog';
import { useChannelProvider } from './hooks/useChannelProvider';
import type { ChannelProviderProps } from './types';

export const ChannelProvider = (props: ChannelProviderProps) => {
  const { children } = props;

  const channelProviderState = useChannelProvider();
  const {
    closeSelectChannelDialog,
    exitedSelectChannelDialog,
    selectChannel,
    selectChannelDialogIntent,
    showSelectChannelDialog,
  } = channelProviderState;

  return (
    <ChannelContext.Provider value={channelProviderState}>
      {children}
      <SelectChannelDialog
        onClose={
          selectChannelDialogIntent !== 'installation'
            ? closeSelectChannelDialog
            : undefined
        }
        onExited={exitedSelectChannelDialog}
        onSelectChannel={selectChannel}
        open={showSelectChannelDialog}
      />
    </ChannelContext.Provider>
  );
};
