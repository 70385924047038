import { useIntl } from 'react-intl';
import { ActionsList } from '../../../ActionsList';
import { CashRegister, Receipt } from '../../../../icons/solid';
import { CardSelector } from '../../../CardSelector';
import { Fieldset } from '../../../Fieldset';
import { usePrint } from '../../../PrintProvider/hooks/usePrint';
import { useOrder } from '../../../OrderProvider/hooks/useOrder';
import { translations } from './translations';

export const GeneralActionsSection = () => {
  const intl = useIntl();
  const { openCashDrawer, printLastPrintedInvoice } = useOrder();
  const { printingEnabled } = usePrint();

  if (!printingEnabled) {
    return null;
  }

  return (
    <Fieldset>
      <ActionsList>
        <CardSelector
          Icon={Receipt}
          label={intl.formatMessage(translations.printLastReceipt)}
          onSelect={printLastPrintedInvoice}
          hideSelectIcon
        />
        <CardSelector
          Icon={CashRegister}
          label={intl.formatMessage(translations.openCashDrawer)}
          onSelect={openCashDrawer}
          hideSelectIcon
        />
      </ActionsList>
    </Fieldset>
  );
};
