import { useCallback, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  addCustomerToOrder as addCustomerToOrderMutationRequest,
  type AddCustomerToOrderInput,
  changeOrderStateToOpen as changeOrderStateToOpenRequest,
  type ChangeOrderStateToOpenInput,
  createOrder as createOrderMutationRequest,
  type CreateOrderInput,
  deleteOrder as deleteOrderMutationRequest,
  type DeleteOrderInput,
  removeCustomerFromOrder as removeCustomerFromOrderMutationRequest,
  type RemoveCustomerFromOrderInput,
  setNoteForOrder as setNoteForOrderMutationRequest,
  type SetNoteForOrderInput,
} from '../../../mutations';
import { getOrder, searchOrder } from '../../../queries';
import type { Channel, Contact, Order, Organisation } from '../../../types';

export interface UseOrderActionsOptions {
  channel: Channel | null;
  order: Order | null;
  setOrder: (order: Order | null) => void;
}

export const useOrderActions = (options: UseOrderActionsOptions) => {
  const { channel, order, setOrder } = options || {};

  const queryClient = useQueryClient();

  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
  const [selectedOrganisation, setSelectedOrganisation] =
    useState<Organisation | null>(null);
  const [showAddCustomerDialog, setShowAddCustomerDialog] = useState(false);
  const [showConfirmDeleteOrderDialog, setShowConfirmDeleteOrderDialog] =
    useState(false);
  const [showManageContactDialog, setShowManageContactDialog] = useState(false);
  const [showManageOrganisationDialog, setShowManageOrganisationDialog] =
    useState(false);
  const [showOrderActionsDialog, setShowOrderActionsDialog] = useState(false);
  const [showOrderNoteDialog, setShowOrderNoteDialog] = useState(false);

  const { mutateAsync: addCustomerToOrderMutation } = useMutation({
    mutationFn: addCustomerToOrderMutationRequest,
  });

  const { mutateAsync: changeOrderStateToOpenMutation } = useMutation({
    mutationFn: changeOrderStateToOpenRequest,
  });

  const { mutateAsync: createOrderMutation } = useMutation({
    mutationFn: createOrderMutationRequest,
  });

  const { mutateAsync: deleteOrderMutation } = useMutation({
    mutationFn: deleteOrderMutationRequest,
  });

  const { mutateAsync: removeCustomerFromOrderMutation } = useMutation({
    mutationFn: removeCustomerFromOrderMutationRequest,
  });

  const { mutateAsync: setNoteForOrderMutation } = useMutation({
    mutationFn: setNoteForOrderMutationRequest,
  });

  const closeAddCustomerDialog = useCallback(() => {
    setShowAddCustomerDialog(false);
  }, []);

  const closeConfirmDeleteOrderDialog = useCallback(() => {
    setShowConfirmDeleteOrderDialog(false);
  }, []);

  const openConfirmDeleteOrderDialog = useCallback(() => {
    setShowConfirmDeleteOrderDialog(true);
  }, []);

  const closeOrderActionsDialog = useCallback(() => {
    setShowOrderActionsDialog(false);
  }, []);

  const closeOrderNoteDialog = useCallback(() => {
    setShowOrderNoteDialog(false);
  }, []);

  const openAddCustomerDialog = useCallback(() => {
    setShowAddCustomerDialog(true);
  }, []);

  const closeManageContactDialog = useCallback(() => {
    setShowManageContactDialog(false);
  }, []);

  const exitedManageContactDialog = useCallback(() => {
    setSelectedContact(null);
  }, []);

  const openManageContactDialog = useCallback((contact: Contact) => {
    setSelectedContact(contact);
    setShowManageContactDialog(true);
  }, []);

  const closeManageOrganisationDialog = useCallback(() => {
    setShowManageOrganisationDialog(false);
  }, []);

  const exitedManageOrganisationDialog = useCallback(() => {
    setSelectedOrganisation(null);
  }, []);

  const openManageOrganisationDialog = useCallback(
    (organisation: Organisation) => {
      setSelectedOrganisation(organisation);
      setShowManageOrganisationDialog(true);
    },
    []
  );

  const openOrderActionsDialog = useCallback(() => {
    setShowOrderActionsDialog(true);
  }, []);

  const openOrderNoteDialog = useCallback(() => {
    setShowOrderNoteDialog(true);
  }, []);

  const fetchOrder = useCallback(
    async (id: string) => {
      const response = await queryClient.fetchQuery(getOrder(id));
      return response?.order || null;
    },
    [queryClient]
  );

  const loadOrder = useCallback(
    async (id: string) => {
      const loadedOrder = await fetchOrder(id);

      if (!loadedOrder) {
        throw new Error('Order could not be loaded');
      }

      setOrder(loadedOrder);
      return loadedOrder;
    },
    [fetchOrder, setOrder]
  );

  const loadOrderByNumber = useCallback(
    async (number: string) => {
      const results = await queryClient.fetchQuery(
        searchOrder(number, {
          ...(channel ? { channel } : {}),
        })
      );

      if (!results.length) {
        throw new Error('Order not found by number');
      }

      // TODO: Do something with multiple results.

      const [firstResult] = results || [];

      if (!firstResult?.id) {
        throw new Error('Invalid order');
      }

      return loadOrder(firstResult.id);
    },
    [channel, loadOrder, queryClient]
  );

  const createOrder = useCallback(
    async (input?: Omit<CreateOrderInput, 'channelId'>) => {
      if (!channel) {
        throw new Error('Channel is required for creating an order');
      }

      const {
        id,
        adjustments,
        billing,
        countryCode,
        currency,
        customer,
        delivery,
        metaData,
        number,
        phoneNumber,
        phoneNumberId,
        sessionId,
        state,
        requestOptions,
      } = input || {};

      const response = await createOrderMutation({
        id,
        adjustments,
        billing,
        channelId: channel.id,
        countryCode,
        currency,
        customer,
        delivery,
        metaData,
        number,
        phoneNumber,
        phoneNumberId,
        sessionId,
        state,
        requestOptions,
      });
      const createdOrder = response?.createOrder?.order || null;

      setOrder(createdOrder);
      return createdOrder;
    },
    [channel, createOrderMutation, setOrder]
  );

  const deleteOrder = useCallback(
    async (
      input?: Omit<DeleteOrderInput, 'orderId'> & { orderId?: string }
    ) => {
      const { orderId } = input || {};
      const currentOrderId = orderId || order?.id;

      if (!currentOrderId) {
        throw new Error('Invalid order');
      }

      await deleteOrderMutation({ orderId: currentOrderId });

      if (currentOrderId === order?.id) {
        setOrder(null);
      }
    },
    [deleteOrderMutation, order, setOrder]
  );

  const addCustomerToOrder = useCallback(
    async (input: Omit<AddCustomerToOrderInput, 'orderId'>) => {
      let updatedOrder = order;

      const { customer, requestOptions } = input || {};

      if (updatedOrder) {
        const response = await addCustomerToOrderMutation({
          orderId: updatedOrder.id || '',
          customer,
          requestOptions,
        });
        updatedOrder = response?.addCustomerToOrder?.order;
      } else {
        updatedOrder = await createOrder({
          customer,
        });
      }

      setOrder(updatedOrder || null);
      return updatedOrder;
    },
    [addCustomerToOrderMutation, createOrder, order, setOrder]
  );

  const removeCustomerFromOrder = useCallback(
    async (input?: Omit<RemoveCustomerFromOrderInput, 'orderId'>) => {
      if (!order) {
        throw new Error('Order not found');
      }

      const { requestOptions } = input || {};

      const response = await removeCustomerFromOrderMutation({
        orderId: order.id,
        requestOptions,
      });
      const updatedOrder = response?.removeCustomerFromOrder?.order || null;

      setOrder(updatedOrder);
      return updatedOrder;
    },
    [removeCustomerFromOrderMutation, order, setOrder]
  );

  const changeOrderStateToOpen = useCallback(
    async (
      input?: Omit<ChangeOrderStateToOpenInput, 'orderId'> & {
        orderId?: string;
      }
    ) => {
      const { orderId } = input || {};
      const currentOrderId = orderId || order?.id;

      if (!currentOrderId) {
        throw new Error('Invalid order');
      }

      const response = await changeOrderStateToOpenMutation({
        orderId: currentOrderId,
      });
      const updatedOrder = response?.openOrder?.order;

      if (currentOrderId === order?.id) {
        setOrder(updatedOrder);
      }

      return updatedOrder;
    },
    [changeOrderStateToOpenMutation, order, setOrder]
  );

  const parkOrder = useCallback(() => {
    setOrder(null);
  }, [setOrder]);

  const setNoteForOrder = useCallback(
    async (input: Omit<SetNoteForOrderInput, 'orderId'>) => {
      if (!order) {
        throw new Error('Order not found');
      }

      const { note, requestOptions } = input || {};

      const response = await setNoteForOrderMutation({
        orderId: order.id,
        note,
        requestOptions,
      });
      const updatedOrder = response?.setNoteForOrder?.order || null;

      setOrder(updatedOrder);
      return updatedOrder;
    },
    [setNoteForOrderMutation, order, setOrder]
  );

  return {
    addCustomerToOrder,
    changeOrderStateToOpen,
    closeAddCustomerDialog,
    closeConfirmDeleteOrderDialog,
    closeManageContactDialog,
    closeManageOrganisationDialog,
    closeOrderActionsDialog,
    closeOrderNoteDialog,
    createOrder,
    deleteOrder,
    exitedManageContactDialog,
    exitedManageOrganisationDialog,
    fetchOrder,
    loadOrder,
    loadOrderByNumber,
    openAddCustomerDialog,
    openConfirmDeleteOrderDialog,
    openManageContactDialog,
    openManageOrganisationDialog,
    openOrderActionsDialog,
    openOrderNoteDialog,
    parkOrder,
    removeCustomerFromOrder,
    selectedContact,
    selectedOrganisation,
    setNoteForOrder,
    showAddCustomerDialog,
    showConfirmDeleteOrderDialog,
    showManageContactDialog,
    showManageOrganisationDialog,
    showOrderActionsDialog,
    showOrderNoteDialog,
  };
};
