import { gql } from '@afosto/graphql-client';
import { CoreChannelFragment } from './ChannelFragment';

export const CoreOrderFragment = gql`
  ${CoreChannelFragment}
  fragment CoreOrderFragment on Order {
    id
    number
    state
    is_vat_shifted
    acceptance {
      amount_covered
    }
    actions {
      action
      ids
    }
    adjustments {
      id
      amount
      description
      is_percentage
      is_discount
      outcome {
        amount
        rule_id
      }
    }
    channel {
      ...CoreChannelFragment
    }
    coupons {
      id
      code
    }
    currency
    customer {
      contact {
        id
        email
        given_name
        additional_name
        family_name
        phone_numbers {
          primary {
            id
            country_code
            number
          }
        }
      }
      organisation {
        id
        name
        administration {
          email
        }
        phone_numbers {
          primary {
            id
            country_code
            number
          }
        }
      }
      notes
    }
    fees {
      shipping {
        description
        total
      }
      payment {
        description
        total
      }
    }
    items {
      ids
      image
      label
      quantity
      sku
      subtotal
      total
      type
      adjustments {
        id
        amount
        description
        is_percentage
        is_discount
        outcome {
          amount
          rule_id
        }
      }
      details {
        meta_data
        serial_number
        pricing {
          amount
        }
      }
    }
    payments {
      id
      amount
      type
      method {
        id
        code
        description
        instruction
        name
        is_manual
      }
      issuer {
        id
        label
      }
      details {
        id
        amount_paid
        amount_total
        currency
        description
        is_authorized
        is_cancelled
        is_captured
        is_expired
        is_paid
        is_pending
        is_pre_authorized
        is_refund
        paid_at
        status
      }
    }
    subtotal
    total
    vat {
      amount
      rate
    }
  }
`;
