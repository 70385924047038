import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  openCashDrawer: {
    id: 'common.openCashDrawer',
    defaultMessage: 'Open cash drawer',
    description: 'Common label for open cash drawer',
  },
  printLastReceipt: {
    id: 'common.printLastReceipt',
    defaultMessage: 'Print last receipt',
    description: 'Common label for print last receipt',
  },
});
