import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';
import { AppProvider } from '../../components/AppProvider';
import { AppSplashScreen } from '../../components/AppSplashScreen';
import { CategoryProvider } from '../../components/CategoryProvider';
import { ChannelProvider } from '../../components/ChannelProvider';
import { Layout } from '../../components/Layout';
import { OrderProvider } from '../../components/OrderProvider';
import { PrintProvider } from '../../components/PrintProvider';

export const Route = createFileRoute('/_protected')({
  beforeLoad: ({ context }) => {
    if (!context.auth?.isAuthenticated) {
      throw redirect({
        to: '/auth/sign-in',
      });
    }
  },
  component: MainLayoutComponent,
});

function MainLayoutComponent() {
  return (
    <ChannelProvider>
      <PrintProvider>
        <AppSplashScreen>
          <AppProvider>
            <CategoryProvider>
              <OrderProvider>
                <Layout>
                  <Outlet />
                </Layout>
              </OrderProvider>
            </CategoryProvider>
          </AppProvider>
        </AppSplashScreen>
      </PrintProvider>
    </ChannelProvider>
  );
}
