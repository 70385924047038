import { useCallback, useEffect, useState } from 'react';
import { useGetLatest } from '@afosto/hooks';
import type { Channel } from '../../../types';

export interface UseAppActionsOptions {
  channel: Channel | null;
}

export const useAppActions = (options: UseAppActionsOptions) => {
  const { channel } = options || {};

  const [showAppActions, setShowAppActions] = useState(false);
  const getLatestShowAppActions = useGetLatest(showAppActions);

  const closeAppActions = useCallback(() => {
    setShowAppActions(false);
  }, []);

  const openAppActions = useCallback(() => {
    setShowAppActions(true);
  }, []);

  useEffect(() => {
    const latestShowAppActions = getLatestShowAppActions();

    if (latestShowAppActions) {
      setShowAppActions(false);
    }
  }, [channel?.id, getLatestShowAppActions]);

  return {
    closeAppActions,
    openAppActions,
    showAppActions,
  };
};
