import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  authenticationErrorDescription: {
    id: 'authentication.error.default.description',
    defaultMessage: 'Something went wrong with the authentication',
    description: 'Description of the authentication default error page',
  },
  authenticationErrorTitle: {
    id: 'authentication.error.default.title',
    defaultMessage: 'Authentication error',
    description: 'title of the authentication default error page',
  },
  authorizeErrorMessage: {
    id: 'feedback.authorizationFailed.message',
    defaultMessage: 'Authorization failed',
    description: 'Feedback message for authorization failed',
  },
  backToApp: {
    id: 'actions.backToApp',
    defaultMessage: 'Back to app',
    description: 'label of the back to app button',
  },
  email: {
    id: 'common.emailAddress',
    defaultMessage: 'Email address',
    description: 'Common label for email address',
  },
  emailAlreadyVerifiedMessage: {
    id: 'feedback.emailAlreadyVerifiedMessage',
    defaultMessage: 'Email address is already verified',
    description:
      'Warning message of the request verification email when the email is already verified',
  },
  emailNotVerifiedErrorDescription: {
    id: 'authentication.error.emailNotVerified.description',
    defaultMessage:
      'You have received an email with a verification link. If the verification link has been expired, you can request a new verification email down below.',
    description:
      'Description of the authentication email not verified error page',
  },
  emailNotVerifiedErrorTitle: {
    id: 'authentication.error.emailNotVerified.title',
    defaultMessage: 'Verify your email',
    description: 'Title of the authentication email not verified error page',
  },
  emailVerificationAlreadySentMessage: {
    id: 'feedback.requestVerificationAlreadySentMessage',
    defaultMessage:
      'Email with a valid verification link has already been sent',
    description:
      'Warning message of the request verification email already been sent',
  },
  forgotPassword: {
    id: 'common.forgotPassword',
    defaultMessage: 'Forgot password?',
    description: 'Common label for forgot password',
  },
  forgotPasswordDescription: {
    id: 'authentication.forgotPassword.description',
    defaultMessage:
      'Enter your email address and you will receive an email with a link to set a new password.',
    description: 'Description of the forgot password page',
  },
  forgotPasswordSuccessDescription: {
    id: 'feedback.forgotPassword.success.description',
    defaultMessage:
      "An email has been sent to the email address '{email}', it contains a link that allows you to choose a new password.",
    description:
      'Description of the success message after requesting a reset link',
  },
  forgotPasswordSuccessTitle: {
    id: 'feedback.forgotPassword.success.title',
    defaultMessage: 'Thank you, check your inbox',
    description: 'Title of the success message after requesting a reset link',
  },
  forgotPasswordTitle: {
    id: 'authentication.forgotPassword.title',
    defaultMessage: 'Forgot password',
    description: 'Title of the forgot password page',
  },
  invalidSessionErrorDescription: {
    id: 'authentication.error.invalidSession.description',
    defaultMessage:
      'Your current session is invalid or has expired. Please sign in again.',
    description: 'Description of the authentication session invalid error page',
  },
  invalidSessionErrorTitle: {
    id: 'authentication.error.invalidSession.title',
    defaultMessage: 'Session invalid',
    description: 'Title of the authentication session invalid error page',
  },
  noAccessToTenantErrorDescription: {
    id: 'authentication.error.tenantNoAccess.description',
    defaultMessage:
      "You don't have access to this organisation. Request access to this organisation or get an invite from the owner.",
    description:
      'Description of the authentication no access to tenant error page',
  },
  noAccessToTenantErrorTitle: {
    id: 'authentication.error.tenantNoAccess.title',
    defaultMessage: 'No access to this organisation',
    description: 'Title of the authentication no access to tenant error page',
  },
  noAccount: {
    id: 'authentication.signIn.noAccountText',
    defaultMessage: `Don't have an account yet?`,
    description: 'Description for the create account link',
  },
  password: {
    id: 'common.password',
    defaultMessage: 'Password',
    description: 'Common label for password',
  },
  passwordResetRequestFailed: {
    id: 'feedback.passwordResetRequestFailed.message',
    defaultMessage: 'Password reset request failed',
    description: 'Feedback message for password reset request failed',
  },
  requestEmailVerificationSuccessMessage: {
    id: 'feedback.requestVerificationEmailSuccessMessage',
    defaultMessage: 'Email with verification link has been sent',
    description: 'Success message of the request verification email action',
  },
  resendVerificationEmail: {
    id: 'actions.resendVerificationEmail',
    defaultMessage: 'Resend verification email',
    description: 'Label of the resend verification email button',
  },
  securityCode: {
    id: 'common.SecurityCode',
    defaultMessage: 'Security code',
    description: 'Common label for security code',
  },
  signIn: {
    id: 'actions.signIn',
    defaultMessage: 'Sign in',
    description: 'Common label for the sign in action',
  },
  signInFailed: {
    id: 'feedback.signInFailed.message',
    defaultMessage: 'Sign in failed',
    description: 'Feedback message for sign in failed',
  },
  signInTitle: {
    id: 'authentication.signIn.title',
    defaultMessage: 'Sign in to your Afosto account',
    description: 'Title of the sign in page',
  },
  signUp: {
    id: 'authentication.signIn.createAccountLink',
    defaultMessage: 'Create account',
    description: 'Common label of the create account link',
  },
  staySignedIn: {
    id: 'common.staySignedIn',
    defaultMessage: 'Stay signed in',
    description: 'Common label for stay signed in',
  },
  submit: {
    id: 'actions.submit',
    defaultMessage: 'Submit',
    description: 'Common label for the submit action',
  },
  twoFactorBackupButtonLabel: {
    id: 'authentication.twoFactorAuthentication.backups.sendBackupCodeLabel',
    defaultMessage: "Also send code to: '{address}'",
    description: 'Label of the send backup code button',
  },
  twoFactorDescription: {
    id: 'authentication.twoFactorAuthentication.description',
    defaultMessage:
      'Verify your account by entering a security code from the Google Authenticator app.',
    description: 'Description of the 2fa page',
  },
  twoFactorAuthenticationFailed: {
    id: 'feedback.twoFactorAuthentication.message',
    defaultMessage: 'Two factor authentication failed',
    description: 'Feedback message for two factor authentication failed',
  },
  twoFactorTitle: {
    id: 'authentication.twoFactorAuthentication.title',
    defaultMessage: 'Two factor authentication',
    description: 'Title of the 2fa page',
  },
  verify: {
    id: 'actions.verify',
    defaultMessage: 'Verify',
    description: 'Label of the verify button',
  },
});
