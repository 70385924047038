import { useIntl } from 'react-intl';
import { useAuthentication } from '@afosto/auth-react';
import { ActionsList } from '../../../ActionsList';
import { CardSelector } from '../../../CardSelector';
import { Fieldset } from '../../../Fieldset';
import { SignOut } from '../../../../icons/solid';
import { translations } from './translations';

export const AccountActionsSection = () => {
  const intl = useIntl();
  const { logout } = useAuthentication();

  const handleSignOut = async () => {
    try {
      await logout();
    } catch {
      // TODO: Do something with error.
    }
  };

  return (
    <Fieldset title={intl.formatMessage(translations.account)}>
      <ActionsList>
        <CardSelector
          color="error"
          Icon={SignOut}
          label={intl.formatMessage(translations.signOut)}
          onSelect={handleSignOut}
        />
      </ActionsList>
    </Fieldset>
  );
};
