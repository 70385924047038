import {
  graphQLClient,
  gql,
  type GraphQLRequestOptions,
} from '@afosto/graphql-client';
import { queryOptions } from '@tanstack/react-query';
import type { Channel } from '../types';
import { CoreChannelFragment } from '../fragments';

export interface GetChannelResponse {
  channel: Channel;
}

export const GET_CHANNEL_QUERY_KEY = 'getChannel';

const fetchChannel = async ({
  queryKey,
}: {
  queryKey: [string, string, GraphQLRequestOptions<GetChannelResponse>];
}) => {
  const [, id, requestOptions = {}] = queryKey;
  const graphQLQuery = gql`
    ${CoreChannelFragment}
    query getChannel($id: String!) {
      channel(id: $id) {
        ...CoreChannelFragment
      }
    }
  `;

  return graphQLClient.request<GetChannelResponse>(
    graphQLQuery,
    { id },
    requestOptions
  );
};

export const getChannel = (
  id: string,
  requestOptions = {} as GraphQLRequestOptions<GetChannelResponse>
) => {
  return queryOptions({
    queryKey: [GET_CHANNEL_QUERY_KEY, id, requestOptions],
    queryFn: fetchChannel,
    select: (response) => response?.channel,
  });
};
