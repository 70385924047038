import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  initializingPosChannels: {
    id: 'feedback.initializingPosChannels',
    defaultMessage: 'Initializing POS channels',
    description: 'Common label for initializing pos channels',
  },
  initializingPrintService: {
    id: 'feedback.initializingPrintService',
    defaultMessage: 'Initializing print service',
    description: 'Common label for initializing print service',
  },
});
