import { useIntl } from 'react-intl';
import { useChannel } from '../ChannelProvider/hooks/useChannel';
import { usePrint } from '../PrintProvider/hooks/usePrint';
import { SplashScreen } from '../SplashScreen';
import { translations } from './translations';
import type { AppSplashScreenProps } from './types';

export const AppSplashScreen = (props: AppSplashScreenProps) => {
  const { children } = props;

  const intl = useIntl();
  const { isInitializingChannel } = useChannel();
  const { isInitializingPrintService } = usePrint();

  if (isInitializingChannel || isInitializingPrintService) {
    let translationKey: keyof typeof translations = 'initializingPosChannels';

    if (isInitializingPrintService) {
      translationKey = 'initializingPrintService';
    }

    return (
      <SplashScreen
        message={intl.formatMessage(translations[translationKey])}
      />
    );
  }

  return children;
};
