import { FormattedMessage, useIntl } from 'react-intl';
import { Typography } from '@afosto/components';
import { ActionsList } from '../../../ActionsList';
import { CardSelector } from '../../../CardSelector';
import { Fieldset } from '../../../Fieldset';
import { Print } from '../../../../icons/solid';
import { usePrint } from '../../../PrintProvider/hooks/usePrint';
import { translations } from './translations';

export const ConnectivityActionsSection = () => {
  const intl = useIntl();
  const { openSelectPrinterDialog, printer } = usePrint();
  const printerIsOnline = printer?.state?.toLowerCase() === 'online';

  return (
    <Fieldset title={intl.formatMessage(translations.connectivity)}>
      <ActionsList>
        {!printer && (
          <CardSelector
            Icon={Print}
            label={intl.formatMessage(translations.addPrinter)}
            onSelect={openSelectPrinterDialog}
          />
        )}
        {printer && (
          <CardSelector
            color={printerIsOnline ? 'success' : 'error'}
            Icon={Print}
            label={printer.name}
            description={
              !printerIsOnline && (
                <Typography color="error" sx={{ mt: 1.5 }}>
                  <FormattedMessage {...translations.printerIsOffline} />
                </Typography>
              )
            }
            onSelect={openSelectPrinterDialog}
            disableLabelColor
          />
        )}
      </ActionsList>
    </Fieldset>
  );
};
