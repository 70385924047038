import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  account: {
    id: 'common.account',
    defaultMessage: 'Account',
    description: 'Common label for account',
  },
  signOut: {
    id: 'common.signOut',
    defaultMessage: 'Sign out',
    description: 'Common label for sign out',
  },
});
