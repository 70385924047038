import { gql } from '@afosto/graphql-client';
import { CoreChannelFragment } from './ChannelFragment';

export const CoreInvoiceFragment = gql`
  ${CoreChannelFragment}
  fragment CoreInvoiceFragment on Invoice {
    id
    created_at
    currency
    is_including_vat
    is_vat_shifted
    number
    subtotal
    total
    adjustments {
      amount
      description
      is_discount
    }
    channel {
      ...CoreChannelFragment
    }
    vendor {
      business {
        id
        name
        addressing {
          billing {
            ...CoreAddressFragment
          }
          visiting {
            ...CoreAddressFragment
          }
        }
        phone_number {
          ...CorePhoneNumberFragment
        }
      }
    }
    lines {
      label
      quantity
      sku
      total
      debit {
        amount
        subtotal
        adjustments {
          amount
          description
          is_discount
        }
      }
    }
    services {
      label
      type
      quantity
      total
      debit {
        amount
        vat_rate
        vat_amount
      }
      credit {
        amount
        vat_rate
        vat_amount
      }
    }
    vat {
      amount
      rate
    }
  }
`;
