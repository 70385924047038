import type { ReactNode } from 'react';
import { useApp } from '../AppProvider/hooks/useApp';
import { BottomNavigation } from '../BottomNavigation';
import { TopBar } from '../TopBar';
import { ActionsDialog } from '../ActionsDialog';
import * as Styled from './Layout.styles';

export const Layout = (props: { children: ReactNode }) => {
  const { children } = props;

  const { closeAppActions, showAppActions } = useApp();

  return (
    <>
      <Styled.Container>
        <TopBar />
        {children}
        <BottomNavigation />
      </Styled.Container>
      <ActionsDialog onClose={closeAppActions} open={showAppActions} />
    </>
  );
};
