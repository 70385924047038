import { useIntl } from 'react-intl';
import { ActionsList } from '../../../ActionsList';
import { CardSelector } from '../../../CardSelector';
import { useChannel } from '../../../ChannelProvider/hooks/useChannel';
import { Fieldset } from '../../../Fieldset';
import { Shop } from '../../../../icons/solid';
import { translations } from './translations';

export const ChannelActionsSection = () => {
  const intl = useIntl();
  const { channel, openSelectChannelDialog } = useChannel();

  return (
    <Fieldset title={intl.formatMessage(translations.channel)}>
      <ActionsList>
        <CardSelector
          Icon={Shop}
          label={intl.formatMessage(translations.changeChannel)}
          description={channel?.name}
          onSelect={openSelectChannelDialog}
        />
      </ActionsList>
    </Fieldset>
  );
};
