import {
  graphQLClient,
  gql,
  type GraphQLRequestOptions,
} from '@afosto/graphql-client';
import { CoreOrderFragment } from '../fragments';
import type { AdjustmentInput } from './addAdjustmentsToOrder';
import type { AddressType } from './createOrder';
import type { Order } from '../types';

export interface AddItemsToOrderResponse {
  addItemsToOrder: {
    order: Order;
  };
}
export type OrderItemTypeInput = 'LOOSE_RETURN' | 'OFFER';

export type OrderItemDeliveryType = 'COLLECT' | 'SHIP';

export interface OrderItemFilterInput {
  key: string;
  value: string;
}

export interface OrderItemSender {
  id: string;
  type: AddressType;
}

export interface OrderItemDeliveryInput {
  type?: OrderItemDeliveryType;
  shippingMethod?: string;
  addressId?: string;
  expectedAt?: number;
  windowStartAt?: number;
  windowEndAt?: number;
  from?: OrderItemSender;
}

export interface OrderItemInput {
  id?: string;
  adjustments?: AdjustmentInput[];
  batchNumber?: string;
  bestBefore?: number;
  brand?: string;
  children?: OrderItemInput[];
  delivery?: OrderItemDeliveryInput;
  expectedAt?: number;
  filters?: OrderItemFilterInput[];
  gtin?: string[];
  image?: string;
  isApproved?: boolean;
  isAvailable?: boolean;
  isBoxed?: boolean;
  isCancelled?: boolean;
  isCollected?: boolean;
  isCovered?: boolean;
  isExpected?: boolean;
  isHanded?: boolean;
  isInvoiced?: boolean;
  isListed?: boolean;
  isMissing?: boolean;
  isPicked?: boolean;
  isReleased?: boolean;
  isReturned?: boolean;
  label?: string;
  metaData?: Record<string, unknown>;
  mpn?: string;
  options?: string[];
  parentItemId?: string;
  position?: string;
  price?: number;
  quantity: number;
  rfid?: string;
  serialNumber?: string;
  shipAt?: number;
  sku: string;
  type?: OrderItemTypeInput;
  url?: string;
  warrantyExpiresAt?: number;
  weight?: number;
  withdrawalExpiresAt?: number;
}

export interface AddItemsToOrderInput {
  orderId: string;
  items: OrderItemInput[];
  requestOptions?: GraphQLRequestOptions<AddItemsToOrderResponse>;
}

export const addItemsToOrder = async ({
  orderId,
  items,
  requestOptions = {},
}: AddItemsToOrderInput) => {
  const mutation = gql`
    ${CoreOrderFragment}
    mutation AddItemsToOrder($input: AddItemsToOrderInput!) {
      addItemsToOrder(input: $input) {
        order {
          ...CoreOrderFragment
        }
      }
    }
  `;

  return graphQLClient.request<AddItemsToOrderResponse>(
    mutation,
    {
      input: {
        orderId,
        items,
      },
    },
    requestOptions
  );
};
