/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/~__root'
import { Route as AuthLayoutImport } from './routes/~auth/~layout'
import { Route as ProtectedLayoutImport } from './routes/~_protected/~layout'
import { Route as AuthCallbackImport } from './routes/~auth_.callback'
import { Route as AuthSignInImport } from './routes/~auth/~sign-in'
import { Route as AuthForgotImport } from './routes/~auth/~forgot'
import { Route as AuthErrorImport } from './routes/~auth/~error'
import { Route as Auth2faImport } from './routes/~auth/~2fa'
import { Route as ProtectedOrdersLayoutImport } from './routes/~_protected/~orders/~layout'
import { Route as ProtectedOrdersTypeImport } from './routes/~_protected/~orders/~$type'
import { Route as ProtectedOrdersIndexImport } from './routes/~_protected/~orders/~index'
import { Route as ProtectedIndexIndexImport } from './routes/~_protected/~_index/~index'

// Create/Update Routes

const AuthLayoutRoute = AuthLayoutImport.update({
  id: '/auth',
  path: '/auth',
  getParentRoute: () => rootRoute,
} as any)

const ProtectedLayoutRoute = ProtectedLayoutImport.update({
  id: '/_protected',
  getParentRoute: () => rootRoute,
} as any)

const AuthCallbackRoute = AuthCallbackImport.update({
  id: '/auth_/callback',
  path: '/auth/callback',
  getParentRoute: () => rootRoute,
} as any)

const AuthSignInRoute = AuthSignInImport.update({
  id: '/sign-in',
  path: '/sign-in',
  getParentRoute: () => AuthLayoutRoute,
} as any)

const AuthForgotRoute = AuthForgotImport.update({
  id: '/forgot',
  path: '/forgot',
  getParentRoute: () => AuthLayoutRoute,
} as any)

const AuthErrorRoute = AuthErrorImport.update({
  id: '/error',
  path: '/error',
  getParentRoute: () => AuthLayoutRoute,
} as any)

const Auth2faRoute = Auth2faImport.update({
  id: '/2fa',
  path: '/2fa',
  getParentRoute: () => AuthLayoutRoute,
} as any)

const ProtectedOrdersLayoutRoute = ProtectedOrdersLayoutImport.update({
  id: '/orders',
  path: '/orders',
  getParentRoute: () => ProtectedLayoutRoute,
} as any)

const ProtectedOrdersTypeRoute = ProtectedOrdersTypeImport.update({
  id: '/$type',
  path: '/$type',
  getParentRoute: () => ProtectedOrdersLayoutRoute,
} as any)

const ProtectedOrdersIndexRoute = ProtectedOrdersIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ProtectedOrdersLayoutRoute,
} as any)

const ProtectedIndexIndexRoute = ProtectedIndexIndexImport.update({
  id: '/_index/',
  path: '/',
  getParentRoute: () => ProtectedLayoutRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_protected': {
      id: '/_protected'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof ProtectedLayoutImport
      parentRoute: typeof rootRoute
    }
    '/auth': {
      id: '/auth'
      path: '/auth'
      fullPath: '/auth'
      preLoaderRoute: typeof AuthLayoutImport
      parentRoute: typeof rootRoute
    }
    '/_protected/orders': {
      id: '/_protected/orders'
      path: '/orders'
      fullPath: '/orders'
      preLoaderRoute: typeof ProtectedOrdersLayoutImport
      parentRoute: typeof ProtectedLayoutImport
    }
    '/auth/2fa': {
      id: '/auth/2fa'
      path: '/2fa'
      fullPath: '/auth/2fa'
      preLoaderRoute: typeof Auth2faImport
      parentRoute: typeof AuthLayoutImport
    }
    '/auth/error': {
      id: '/auth/error'
      path: '/error'
      fullPath: '/auth/error'
      preLoaderRoute: typeof AuthErrorImport
      parentRoute: typeof AuthLayoutImport
    }
    '/auth/forgot': {
      id: '/auth/forgot'
      path: '/forgot'
      fullPath: '/auth/forgot'
      preLoaderRoute: typeof AuthForgotImport
      parentRoute: typeof AuthLayoutImport
    }
    '/auth/sign-in': {
      id: '/auth/sign-in'
      path: '/sign-in'
      fullPath: '/auth/sign-in'
      preLoaderRoute: typeof AuthSignInImport
      parentRoute: typeof AuthLayoutImport
    }
    '/auth_/callback': {
      id: '/auth_/callback'
      path: '/auth/callback'
      fullPath: '/auth/callback'
      preLoaderRoute: typeof AuthCallbackImport
      parentRoute: typeof rootRoute
    }
    '/_protected/_index/': {
      id: '/_protected/_index/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof ProtectedIndexIndexImport
      parentRoute: typeof ProtectedLayoutImport
    }
    '/_protected/orders/': {
      id: '/_protected/orders/'
      path: '/'
      fullPath: '/orders/'
      preLoaderRoute: typeof ProtectedOrdersIndexImport
      parentRoute: typeof ProtectedOrdersLayoutImport
    }
    '/_protected/orders/$type': {
      id: '/_protected/orders/$type'
      path: '/$type'
      fullPath: '/orders/$type'
      preLoaderRoute: typeof ProtectedOrdersTypeImport
      parentRoute: typeof ProtectedOrdersLayoutImport
    }
  }
}

// Create and export the route tree

interface ProtectedOrdersLayoutRouteChildren {
  ProtectedOrdersIndexRoute: typeof ProtectedOrdersIndexRoute
  ProtectedOrdersTypeRoute: typeof ProtectedOrdersTypeRoute
}

const ProtectedOrdersLayoutRouteChildren: ProtectedOrdersLayoutRouteChildren = {
  ProtectedOrdersIndexRoute: ProtectedOrdersIndexRoute,
  ProtectedOrdersTypeRoute: ProtectedOrdersTypeRoute,
}

const ProtectedOrdersLayoutRouteWithChildren =
  ProtectedOrdersLayoutRoute._addFileChildren(
    ProtectedOrdersLayoutRouteChildren,
  )

interface ProtectedLayoutRouteChildren {
  ProtectedOrdersLayoutRoute: typeof ProtectedOrdersLayoutRouteWithChildren
  ProtectedIndexIndexRoute: typeof ProtectedIndexIndexRoute
}

const ProtectedLayoutRouteChildren: ProtectedLayoutRouteChildren = {
  ProtectedOrdersLayoutRoute: ProtectedOrdersLayoutRouteWithChildren,
  ProtectedIndexIndexRoute: ProtectedIndexIndexRoute,
}

const ProtectedLayoutRouteWithChildren = ProtectedLayoutRoute._addFileChildren(
  ProtectedLayoutRouteChildren,
)

interface AuthLayoutRouteChildren {
  Auth2faRoute: typeof Auth2faRoute
  AuthErrorRoute: typeof AuthErrorRoute
  AuthForgotRoute: typeof AuthForgotRoute
  AuthSignInRoute: typeof AuthSignInRoute
}

const AuthLayoutRouteChildren: AuthLayoutRouteChildren = {
  Auth2faRoute: Auth2faRoute,
  AuthErrorRoute: AuthErrorRoute,
  AuthForgotRoute: AuthForgotRoute,
  AuthSignInRoute: AuthSignInRoute,
}

const AuthLayoutRouteWithChildren = AuthLayoutRoute._addFileChildren(
  AuthLayoutRouteChildren,
)

export interface FileRoutesByFullPath {
  '': typeof ProtectedLayoutRouteWithChildren
  '/auth': typeof AuthLayoutRouteWithChildren
  '/orders': typeof ProtectedOrdersLayoutRouteWithChildren
  '/auth/2fa': typeof Auth2faRoute
  '/auth/error': typeof AuthErrorRoute
  '/auth/forgot': typeof AuthForgotRoute
  '/auth/sign-in': typeof AuthSignInRoute
  '/auth/callback': typeof AuthCallbackRoute
  '/': typeof ProtectedIndexIndexRoute
  '/orders/': typeof ProtectedOrdersIndexRoute
  '/orders/$type': typeof ProtectedOrdersTypeRoute
}

export interface FileRoutesByTo {
  '/auth': typeof AuthLayoutRouteWithChildren
  '/auth/2fa': typeof Auth2faRoute
  '/auth/error': typeof AuthErrorRoute
  '/auth/forgot': typeof AuthForgotRoute
  '/auth/sign-in': typeof AuthSignInRoute
  '/auth/callback': typeof AuthCallbackRoute
  '/': typeof ProtectedIndexIndexRoute
  '/orders': typeof ProtectedOrdersIndexRoute
  '/orders/$type': typeof ProtectedOrdersTypeRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_protected': typeof ProtectedLayoutRouteWithChildren
  '/auth': typeof AuthLayoutRouteWithChildren
  '/_protected/orders': typeof ProtectedOrdersLayoutRouteWithChildren
  '/auth/2fa': typeof Auth2faRoute
  '/auth/error': typeof AuthErrorRoute
  '/auth/forgot': typeof AuthForgotRoute
  '/auth/sign-in': typeof AuthSignInRoute
  '/auth_/callback': typeof AuthCallbackRoute
  '/_protected/_index/': typeof ProtectedIndexIndexRoute
  '/_protected/orders/': typeof ProtectedOrdersIndexRoute
  '/_protected/orders/$type': typeof ProtectedOrdersTypeRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/auth'
    | '/orders'
    | '/auth/2fa'
    | '/auth/error'
    | '/auth/forgot'
    | '/auth/sign-in'
    | '/auth/callback'
    | '/'
    | '/orders/'
    | '/orders/$type'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/auth'
    | '/auth/2fa'
    | '/auth/error'
    | '/auth/forgot'
    | '/auth/sign-in'
    | '/auth/callback'
    | '/'
    | '/orders'
    | '/orders/$type'
  id:
    | '__root__'
    | '/_protected'
    | '/auth'
    | '/_protected/orders'
    | '/auth/2fa'
    | '/auth/error'
    | '/auth/forgot'
    | '/auth/sign-in'
    | '/auth_/callback'
    | '/_protected/_index/'
    | '/_protected/orders/'
    | '/_protected/orders/$type'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  ProtectedLayoutRoute: typeof ProtectedLayoutRouteWithChildren
  AuthLayoutRoute: typeof AuthLayoutRouteWithChildren
  AuthCallbackRoute: typeof AuthCallbackRoute
}

const rootRouteChildren: RootRouteChildren = {
  ProtectedLayoutRoute: ProtectedLayoutRouteWithChildren,
  AuthLayoutRoute: AuthLayoutRouteWithChildren,
  AuthCallbackRoute: AuthCallbackRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "~__root.tsx",
      "children": [
        "/_protected",
        "/auth",
        "/auth_/callback"
      ]
    },
    "/_protected": {
      "filePath": "~_protected/~layout.tsx",
      "children": [
        "/_protected/orders",
        "/_protected/_index/"
      ]
    },
    "/auth": {
      "filePath": "~auth/~layout.tsx",
      "children": [
        "/auth/2fa",
        "/auth/error",
        "/auth/forgot",
        "/auth/sign-in"
      ]
    },
    "/_protected/orders": {
      "filePath": "~_protected/~orders/~layout.tsx",
      "parent": "/_protected",
      "children": [
        "/_protected/orders/",
        "/_protected/orders/$type"
      ]
    },
    "/auth/2fa": {
      "filePath": "~auth/~2fa.tsx",
      "parent": "/auth"
    },
    "/auth/error": {
      "filePath": "~auth/~error.tsx",
      "parent": "/auth"
    },
    "/auth/forgot": {
      "filePath": "~auth/~forgot.tsx",
      "parent": "/auth"
    },
    "/auth/sign-in": {
      "filePath": "~auth/~sign-in.tsx",
      "parent": "/auth"
    },
    "/auth_/callback": {
      "filePath": "~auth_.callback.tsx"
    },
    "/_protected/_index/": {
      "filePath": "~_protected/~_index/~index.tsx",
      "parent": "/_protected"
    },
    "/_protected/orders/": {
      "filePath": "~_protected/~orders/~index.tsx",
      "parent": "/_protected/orders"
    },
    "/_protected/orders/$type": {
      "filePath": "~_protected/~orders/~$type.tsx",
      "parent": "/_protected/orders"
    }
  }
}
ROUTE_MANIFEST_END */
