import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  availablePaymentMethods: {
    id: 'common.availablePaymentMethods',
    defaultMessage: 'Available payment methods',
    description: 'Common label for available payment methods',
  },
  back: {
    id: 'actions.back',
    defaultMessage: 'Back',
    description: 'Common label for the back action',
  },
  close: {
    id: 'actions.close',
    defaultMessage: 'Close',
    description: 'Common label for the close action',
  },
  completeOrder: {
    id: 'actions.completeOrder',
    defaultMessage: 'Complete order',
    description: 'Common label for the complete order action',
  },
  description: {
    id: 'components.selectPaymentMethodDialog.description',
    defaultMessage: 'Select a payment method for checkout',
    description: 'Description of the select payment method dialog',
  },
  makePaymentLater: {
    id: 'actions.makePaymentLater',
    defaultMessage: 'Make payment later',
    description: 'Common label for the make payment later action',
  },
  noOutstandingBalance: {
    id: 'feedback.noOutstandingBalance.message',
    defaultMessage: 'There is no outstanding balance',
    description: 'Common feedback message for no outstanding balance',
  },
  noPaymentRequired: {
    id: 'feedback.noPaymentRequired.message',
    defaultMessage: 'No payment required',
    description: 'Common feedback message for no payment required',
  },
  orderCompleted: {
    id: 'common.orderCompleted',
    defaultMessage: 'Order completed',
    description: 'Common label for order completed',
  },
  orderOpened: {
    id: 'common.orderOpened',
    defaultMessage: 'Order opened',
    description: 'Common label for order opened',
  },
  paidWith: {
    id: 'common.paidWith',
    defaultMessage: 'Paid with',
    description: 'Common label for paid with',
  },
  paymentAccepted: {
    id: 'feedback.paymentAccepted.message',
    defaultMessage: 'Payment accepted',
    description: 'Common feedback message for payment accepted',
  },
  printOrder: {
    id: 'actions.printOrder',
    defaultMessage: 'Print order',
    description: 'Common label for the print order action',
  },
  printReceipt: {
    id: 'actions.printReceipt',
    defaultMessage: 'Print receipt',
    description: 'Common label for the print receipt action',
  },
  processingPayment: {
    id: 'feedback.processingPayment.message',
    defaultMessage: 'Processing payment',
    description: 'Common feedback message for processing payment',
  },
  retry: {
    id: 'actions.retry',
    defaultMessage: 'Retry',
    description: 'Common label for the retry action',
  },
  underpaid: {
    id: 'common.underpaid',
    defaultMessage: 'Underpaid',
    description: 'Common label for underpaid',
  },
});
