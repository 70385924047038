import { Typography } from '@afosto/components';
import { useApp } from '../AppProvider/hooks/useApp';
import { useChannel } from '../ChannelProvider/hooks/useChannel';
import { EllipsisV } from '../../icons/solid';
import * as Styled from './TopBar.styles';

export const TopBar = () => {
  const { openAppActions } = useApp();
  const { channel } = useChannel();

  return (
    <Styled.AppBar position="static">
      <Styled.Toolbar disableGutters>
        <Styled.ToolbarTitle>
          {channel && (
            <Typography variant="h4" color="gray.200">
              {channel.name}
            </Typography>
          )}
        </Styled.ToolbarTitle>
        <Styled.ToolbarActions>
          <Styled.ToolbarIconButton
            color="inherit"
            onClick={openAppActions}
            size="large"
            variant="minimalLight1"
          >
            <EllipsisV sx={{ fontSize: 24 }} />
          </Styled.ToolbarIconButton>
        </Styled.ToolbarActions>
      </Styled.Toolbar>
    </Styled.AppBar>
  );
};
