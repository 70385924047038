import { useIntl } from 'react-intl';
import { createFileRoute } from '@tanstack/react-router';
import { AuthenticationError } from '@afosto/auth-react';
import { Grid } from '@afosto/components';
import { translations } from './translations';

export const Route = createFileRoute('/auth/error')({
  component: AuthErrorComponent,
});

function AuthErrorComponent() {
  const intl = useIntl();

  return (
    <Grid item xs={12} sm={8} md={6}>
      <AuthenticationError
        backToAppLabel={intl.formatMessage(translations.backToApp)}
        defaultAuthenticationErrorDescription={intl.formatMessage(
          translations.authenticationErrorDescription
        )}
        defaultAuthenticationErrorTitle={intl.formatMessage(
          translations.authenticationErrorTitle
        )}
        defaultAuthorizeErrorMessage={intl.formatMessage(
          translations.authorizeErrorMessage
        )}
        emailAlreadyVerifiedMessage={intl.formatMessage(
          translations.emailAlreadyVerifiedMessage
        )}
        emailNotVerifiedErrorDescription={intl.formatMessage(
          translations.emailNotVerifiedErrorDescription
        )}
        emailNotVerifiedErrorTitle={intl.formatMessage(
          translations.emailNotVerifiedErrorTitle
        )}
        emailVerificationAlreadySentMessage={intl.formatMessage(
          translations.emailVerificationAlreadySentMessage
        )}
        invalidSessionErrorDescription={intl.formatMessage(
          translations.invalidSessionErrorDescription
        )}
        invalidSessionErrorTitle={intl.formatMessage(
          translations.invalidSessionErrorTitle
        )}
        noAccessToTenantErrorDescription={intl.formatMessage(
          translations.noAccessToTenantErrorDescription
        )}
        noAccessToTenantErrorTitle={intl.formatMessage(
          translations.noAccessToTenantErrorTitle
        )}
        requestEmailVerificationSuccessMessage={intl.formatMessage(
          translations.requestEmailVerificationSuccessMessage
        )}
        resendVerificationEmailLabel={intl.formatMessage(
          translations.resendVerificationEmail
        )}
        signInLabel={intl.formatMessage(translations.signIn)}
      />
    </Grid>
  );
}
